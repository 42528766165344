import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomMarkdownRenderer from "../components/Markdown.js";
import jobitt from "../assets/BlogContent/jobit.webp";
import "./BlogContent.css";
import useFetch from "../hooks/useFetch.js";
import Popup from "../components/popup.js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Lottie from "react-lottie";
import loading from "../pages/loading.json";

function titleToSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remove non-word chars
    .replace(/[\s_-]+/g, "-") // Replace spaces and underscores with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens
}

const BlogContent = () => {
  const {
    loading: blogsLoading,
    data: blogs,
    error: blogsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/data.json`
  );

  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { blogTitle } = useParams();
  const {meta_description}= useParams();
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState(null);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const isManualScrollRef = useRef(false);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  let blog = {};

  const NAVBAR_HEIGHT = 110;
  const SCROLL_OFFSET = 100;
  useEffect(() => {
    if (blogs) {
      const currentBlog = blogs.find(
        (blog) => titleToSlug(blog.blogTitle) === blogTitle
      );

      if (currentBlog && currentBlog.blogCategory) {
        // Filter blogs from the same category only
        const sameCategoryBlogs = blogs.filter(
          (blog) =>
            blog.blogCategory === currentBlog.blogCategory && // Match exact category
            titleToSlug(blog.blogTitle) !== blogTitle // Exclude current blog
        );

        // Sort by date (newest first)
        const sortedBlogs = sameCategoryBlogs.sort((a, b) => {
          const dateA = new Date(a.blogDate.split(".").reverse().join("-"));
          const dateB = new Date(b.blogDate.split(".").reverse().join("-"));
          return dateB - dateA;
        });

        // Get the 4 most recent from same category
        setRelatedBlogs(sortedBlogs.slice(0, 5));
      }
    }
  }, [blogs, blogTitle]);


  const titleToSlug = (title) => {
    if (!title) return '';
    return title
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')  
      .trim()
      .replace(/\s+/g, '-');     
  };


  const [blogMetaData, setBlogMetaData] = useState({ 
    title: '', 
    description: '', 
    slug: '' 
  });

  useEffect(() => {
    if (blogs) {
      const currentBlog = blogs.find(
        (blog) => titleToSlug(blog.blogTitle) === blogTitle
      );
      
      if (currentBlog) {
        setBlogMetaData({
          title: currentBlog.blogTitle || 'Blog Post',
          description: currentBlog.meta_description || `Read about ${currentBlog.blogTitle}`,
          slug: titleToSlug(currentBlog.blogTitle)
        });
      }
    }
  }, [blogs, blogTitle]);

  useEffect(() => {
    if (blogMetaData.title) {
      document.title = blogMetaData.title;

      // Meta description
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = blogMetaData.description;

      // Open Graph tags
      let ogTitle = document.querySelector('meta[property="og:title"]');
      if (!ogTitle) {
        ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        document.head.appendChild(ogTitle);
      }
      ogTitle.content = blogMetaData.title;

      let ogDescription = document.querySelector('meta[property="og:description"]');
      if (!ogDescription) {
        ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        document.head.appendChild(ogDescription);
      }
      ogDescription.content = blogMetaData.description;

      // Canonical link
      let canonicalLink = document.querySelector('link[rel="canonical"]');
      if (!canonicalLink) {
        canonicalLink = document.createElement('link');
        canonicalLink.rel = "canonical";
        document.head.appendChild(canonicalLink);
      }
      canonicalLink.href = `https://randomwalk.ai/blog/${blogMetaData.slug}/`;
    }
  }, [blogMetaData]);



  useEffect(() => {
    const updateActiveHeading = () => {
      if (isManualScrollRef.current) {
        return;
      }

      const scrollPosition = window.scrollY + NAVBAR_HEIGHT + SCROLL_OFFSET;

      let activeHeading = null;
      for (let i = headings?.length - 1; i >= 0; i--) {
        const element = document.getElementById(headings[i].id);
        if (element && element.offsetTop <= scrollPosition) {
          activeHeading = headings[i].id;
          break;
        }
      }

      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 50
      ) {
        activeHeading = headings[headings?.length - 1]?.id;
      }

      if (activeHeading) {
        setActiveHeading(activeHeading);
      }
    };

    const scrollSidebarToActiveHeading = () => {
      if (sidebarRef.current && activeHeading) {
        const activeElement = sidebarRef.current.querySelector(
          `[data-id="${activeHeading}"]`
        );

        if (activeElement) {
          const sidebarTop = sidebarRef.current.offsetTop;
          const activeElementTop = activeElement.offsetTop;
          const sidebarHeight = sidebarRef.current.clientHeight;
          const activeElementHeight = activeElement.clientHeight;

          let targetScrollTop =
            activeElementTop - sidebarHeight / 2 + activeElementHeight / 2;
          targetScrollTop = Math.max(
            0,
            Math.min(
              targetScrollTop,
              sidebarRef.current.scrollHeight - sidebarHeight
            )
          );

          sidebarRef.current.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",
          });
        }
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(() => {
        updateActiveHeading();
        scrollSidebarToActiveHeading();
      });
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateActiveHeading);

    updateActiveHeading();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateActiveHeading);
    };
  }, [headings, activeHeading]);

  if (blogs) {
    let arr = blogs?.filter(
      (blog) => titleToSlug(blog.blogTitle) === blogTitle
    );
    blog = arr[0];
  }
  if (!blog) {
    return <div>Blog not found</div>;
  }

  const handleScrollToHeading = (id) => {
    isManualScrollRef.current = true;
    setActiveHeading(id);

    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;

      const offsetPosition =
        elementPosition + window.pageYOffset - NAVBAR_HEIGHT - SCROLL_OFFSET;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      setTimeout(() => {
        isManualScrollRef.current = false;
      }, 1000); // Adjust this delay if needed
    }
  };

  // Filter for 4 most recent blogs of the same category

  if (blogsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }
  if (blogsError) return <div>Error loading blogs!</div>;
  if (!blogs) return null;
  const showAuthorInfo = blog?.author_img1?.url && blog?.author_name1;
  const showSecondAuthor = blog?.author_img2?.url && blog?.author_name2;
  return (
    <>
      {/* <Helmet>
        <title>{blog.blogTitle}</title>
        <meta
          name="description"
          content={blog.meta_description || `Read about ${blog.blogTitle}`}
        />
        <meta property="og:title" content={blog.blogTitle} />
        <meta
          property="og:description"
          content={blog.meta_description || `Read about ${blog.blogTitle}`}
        />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/blog/${titleToSlug(blog.blogTitle)}/`}
        />
      </Helmet> */}
      <section className="flex flex-col justify-center items-start pt-12">
        <section className="w-full md:w-full px-10 md:px-20 md:pt-10 2xl:pt-10">
          <div className="flex items-start py-3 pt-10">
            <div>
              <h1 className="text-sm font-bold 2xl:text-2xl lg:text-3xl text-pink-600 font-display">
                The Random Walk Blog
              </h1>
              <p className="text-sm lg:text-lg font-display text-[#666666] font-bold 2xl:text-2xl">
                {blog.blogDate}
              </p>
            </div>
          </div>

          <h1 className="font-bold 2xl:text-5xl md:text-4xl text-[#666666] font-display text-xl md:text-left">
            {blog.blogTitle}
          </h1>
        </section>

        <img
          className="h-auto w-full object-cover px-10 py-5 md:px-10 lg:px-20 md:py-5"
          src={`${blog.coverImg?.url}`}
          alt={blog.blogTitle}
        />

        <section className="flex flex-col md:flex-row md:px-20 text-[#666666] w-full justify-center">
          <aside className="lg:w-1/3">
            <div className="text-[#666666] max-md:hidden  md:-ml-12 lg:-ml-0 sidebar font-display sticky 2xl:top-16 2xl:pt-5">
            {!showAuthorInfo && (
      <figure className="sidebar-header md:translate-y-12 lg:translate-y-10 2xl:translate-y-0 flex items-center -mb-5">
      <img
        src={jobitt}
        alt="Jobit"
        className="w-20 rounded-full mr-4"
      />
      <div>
        <div className="font-bold">Jobit</div>
        <div className="text-sm font-medium mt-1">Content Writer</div>
      </div>
    </figure>
      )}
      
      {showAuthorInfo && (
        <figure className="sidebar-header md:translate-y-12 lg:translate-y-10 2xl:translate-y-0 flex items-center">
          <div className="flex items-center space-x-4 ">
            <img
              src={blog.author_img1.url}
              alt={blog.author_name1}
              className="w-20 h-20 rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl font-semibold">
                {blog.author_name1}
              </h3>
              <h3 className="text-sm font-medium mt-1">
                {blog.author_designation1}
              </h3>
            </div>
          </div>
        </figure>
        
      )}
       {showSecondAuthor && (
      <figure className="sidebar-header md:translate-y-12 lg:translate-y-10 2xl:translate-y-0 flex items-center">
        <div className="flex items-center space-x-4 ">
          <img
            src={blog.author_img2.url}
            alt={blog.author_name2}
            className="w-20 h-20 rounded-full object-cover"
          />
          <div>
            <h3 className="text-xl font-semibold">
              {blog.author_name2}
            </h3>
            <h3 className="text-sm font-medium mt-1">
              {blog.author_designation2}
            </h3>
          </div>
        </div>
      </figure>
    )}
              <div
                ref={sidebarRef}
                className="sidebar-content md:px-2 lg:mt-5 2xl:mt-5"
                style={{ maxHeight: "calc(100vh - 12rem)", overflowY: "auto" }}
              >
                <ul className="headings-list  md:h-full">
                  {headings.map((heading, index) => (
                    <li
                      key={index}
                      data-id={heading.id}
                      className={`heading-item lg:text-[15px] md:text-[10px] 2xl:text-[20px] md:translate-y-12 lg:translate-y-12 2xl:translate-y-0 ${
                        activeHeading === heading.id ? "active" : ""
                      } level-${heading.level}`}
                      onClick={() => handleScrollToHeading(heading.id)}
                    >
                      {heading.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </aside>

          <section
            ref={contentRef}
            className="md:w-full mb-20 lg:pr-32 px-10 md:px-5 lg:px-0 lg:pl-10"
          >
            <article className="font-display text-[#666666] md:text-sm 2xl:text-xl">
              <CustomMarkdownRenderer
                content={blog.blogContent.replaceAll(
                  "http://localhost:1337/uploads/",
                  "https://devrw.blob.core.windows.net/rw-website/assets/"
                )}
                setHeadings={setHeadings}
              />
            </article>
          </section>
        </section>
      </section>

      {relatedBlogs.length > 0 && (
        <div className="-translate-y-10 mx-auto">
          <h2 className="lg:text-4xl md:text-2xl font-bold md:my-5 text-pink-600 md:py-0 px-5 text-3xl md:px-10 md:pb-10 text-center">
            Related Blogs
          </h2>
          <section className="grid max-md:hidden text-[#666666] lg:grid-cols-5 md:px-10 md:grid-cols-5 sm:grid-cols-2 ss:grid-cols-1 gap-5 px-4 h-[28rem] overflow-hidden md:pt-32 -translate-y-1/3 md:mt-10">
            {relatedBlogs.map((blog, index) => (
              <Link
                key={blog.id}
                to={`/blog/${titleToSlug(blog.blogTitle)}/`}
                className="group hover:-translate-y-1 transform transition-all duration-300"
              >
                <article
                  className={`group-hover:-translate-y-12 transform w-full relative rounded-xl overflow-hidden drop-shadow-md flex flex-col justify-between transition-all duration-300 ease-in-out h-80 
              ${
                index === 0
                  ? "bg-[#EBF7FF]"
                  : index === 1
                  ? "bg-[#a9b4df]"
                  : "bg-[#FFEBF4]"
              }
            `}
                >
                  <article className="p-5 transition-transform duration-300 ease-in-out transform">
                    <h2 className="font-bold text-left md:text-xs lg:line-clamp-3 pb-5">
                      {blog.blogTitle}
                    </h2>
                    <p className="text-gray-600 text-sm md:line-clamp-5 lg:line-clamp-10">
                      {blog.blogDesc}
                    </p>
                  </article>
                </article>
                <article className="overflow-hidden rounded-lg transform transition-all group-hover:-translate-y-full">
                  <img
                    className="w-full object-cover h-40"
                    src={`${blog.coverImg.url}`}
                    alt={blog.blogTitle}
                  />
                </article>
              </Link>
            ))}
          </section>
        </div>
      )}

      {relatedBlogs.length > 0 && (
        <section className="flex md:hidden scroll-hide gap-5 overflow-x-auto px-4 text-black h-[28rem]">
          {relatedBlogs.map((blog, index) => (
            <Link
              key={blog.id}
              to={`/blog/${titleToSlug(blog.blogTitle)}/`}
              className="min-w-[16rem]"
            >
              <div
                className={`w-full shadow-md relative rounded-xl overflow-hidden drop-shadow-md flex flex-col h-80 
            ${
              index === 0
                ? "bg-[#EBF7FF]"
                : index === 1
                ? "bg-[#a9b4df]"
                : "bg-[#FFEBF4]"
            }
          `}
              >
                <img
                  className="w-full object-cover h-40"
                  src={`${blog.coverImg.url}`}
                  alt={blog.blogTitle}
                />
                <div className="p-4">
                  <h2 className="font-bold text-left text-xs md:text-sm line-clamp-5 pb-2">
                    {blog.blogTitle}
                  </h2>
                  <p className="text-gray-600 text-xs md:text-sm line-clamp-3 pb-4">
                    {blog.blogDesc}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </section>
      )}

      <div className=" mt-0 md:-mt-10 ">
        <Popup utmContent={`blog/${blogTitle}`} />
      </div>
    </>
  );
};

export default BlogContent;
