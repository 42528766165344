import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import eventt from "../assets/Events/event.webp";
import conference from "../assets/Events/Conference.jpg";
import bubbles from "../assets/Events/bubbles.webp";
import { Helmet } from "react-helmet-async";
import useFetch from "../hooks/useFetch.js";

import Popup from "../components/popup.js";

const Media = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImageModal = (eventIndex, imageIndex) => {
    setSelectedEvent(eventIndex);
    setSelectedImageIndex(imageIndex);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedImageIndex(null);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(5);

  const {
    loading: mediafutureLoading,
    data: mediafutureData,
    error: mediafutureError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/mediafuture/data.json`
  );
  console.log("eventssss:", mediafutureData);

  const {
    loading: mediaspeakerLoading,
    data: mediaspeakerData,
    error: mediaspeakerError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/mediaspeaker/data.json`
  );

  console.log("testss:", mediaspeakerData);
  useEffect(() => {
    const updateItemsPerView = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(5);
      }
    };

    updateItemsPerView();

    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
      updateItemsPerView();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigateImage = (direction) => {
    if (selectedEvent === null) return;

    const currentEvent = mediafutureData?.[selectedEvent];
    setSelectedImageIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      const totalImages = currentEvent.eventImg?.length;
      if (newIndex < 0) return totalImages - 1;
      if (newIndex >= totalImages) return 0;
      return newIndex;
    });
  };

  const slideLeft = () => {
    setSlideIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : speakerList?.length - itemsPerView
    );
  };

  const slideRight = () => {
    setSlideIndex((prevIndex) =>
      prevIndex < speakerList?.length - itemsPerView ? prevIndex + 1 : 0
    );
  };

  const activeSlideIndex = Math.floor(slideIndex / itemsPerView);

  const eventList = mediafutureData || [];
  const speakerList = mediaspeakerData || [];

  const getYouTubeVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i
    );
    return match ? match[1] : null;
  };

  useEffect(() => {
    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    // Set page title
    document.title = "MediaFutures Conference Series 2025: AI in Media | Random Walk & University of Madras";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Join leading AI and media experts at the MediaFutures Conference to discuss AI-driven content creation, journalism, and audience engagement.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Join leading AI and media experts at the MediaFutures Conference to discuss AI-driven content creation, journalism, and audience engagement.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "MediaFutures Conference Series 2025: AI in Media | Random Walk & University of Madras");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Join leading AI and media experts at the MediaFutures Conference to discuss AI-driven content creation, journalism, and audience engagement.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/media-futures/";
  }, []);
  return (
    <section className="Main2 md:max-w-full 2xl:max-w-full  font-display text-[#666666] lg:mt-10 2xl:mt-0 ">
      {/* <Helmet>
        <title>Join AI Events for Innovation & Collaboration | Random Walk</title>
        <meta
          property="og:title"
          content="Join AI Events for Innovation & Collaboration | Random Walk"
        />
        <meta
          name="description"
          content="Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities."
        />
        <meta
          property="og:description"
          content="Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities."
        />
        <link rel="canonical" href="https://randomwalk.ai/playlab/" />
      </Helmet> */}
<section className=" lg:hidden  bg-gradient_background4">
        <article className="flex flex-wrap items-center">
          <div className=" md:ml-5 lg:ml-20 md:pt-5 pt-16 lg:pt-10 flex items-center py-5 md:py-12">
            <div className="flex flex-row bg-cover bg-no-repeat rounded-2xl pt-10 ">
              <div className="flex  md:flex-row px-3  lg:items-start lg:pl-10 w-full">
                <div className="flex flex-col md:mt-20 md:ml-10  2xl:pt-32">
                  <h2 className="font-display  md:text-2xl lg:text-4xl 2xl:text-5xl font-semibold pb-2 md:pb-4 text-left">
                  Attend the MediaFutures Conference Series: AI in Media
                  </h2>
                  <p className="text-xs font-display md:text-sm lg:text-base 2xl:text-xl text-left ">
                   Be part of Random Walk’s AI conferences, starting with the first edition of the MediaFutures Conference Series: AI in Media- Navigating the Future of Media, Creativity, Communication, and Innovation, in collaboration with the University of Madras. This landmark event will explore the role of AI in media, communication, and technology, bringing together experts from academia and industry.
                  </p>
                </div>

                <figure className="flex justify-end lg:w-full md:-mt-0 lg:-mt-20  lg:h-auto">
                  <img src={bubbles} alt="Event" />
                </figure>
              </div>
            </div>
          </div>
        </article>
      </section>
<section className="max-lg:hidden bg-gradient_background4 lg:h-[550px] 2xl:h-[800px]">
  <article className="flex flex-wrap items-center">
    <div className="md:ml-5 lg:ml-20 md:pt-5 pt-16 lg:pt-10 flex items-center py-5 md:py-12">
      <div className="flex flex-row bg-cover bg-no-repeat rounded-2xl pt-10">
        <div className="flex md:flex-row px-3 lg:items-start lg:pl-10 w-full">
          {/* Text content - reduced width to give more space to image */}
          <div className="flex flex-col md:mt-10 md:ml-2 2xl:ml-32 2xl:pt-32 lg:w-1/2 ">
            <h2 className="font-display md:text-2xl lg:text-4xl 2xl:text-5xl font-semibold pb-2 md:pb-4 text-left">
              Attend the MediaFutures Conference Series: AI in Media
            </h2>
            <p className="text-xs font-display md:text-sm lg:text-base 2xl:text-xl text-left">
              Be part of Random Walk's AI conferences, starting with the first edition of the MediaFutures Conference Series: AI in Media - Navigating the Future of Media, Creativity, Communication, and Innovation, in collaboration with the University of Madras. This landmark event will explore the role of AI in media, communication, and technology, bringing together experts from academia and industry.
            </p>
          </div>
          
          {/* Image container - increased width and adjusted margins */}
          <figure className="flex justify-end  lg:w-1/2 md:-mt-0 lg:-mt-20">
            <img 
              src={bubbles} 
              alt="Event" 
              className="  lg:w-[480px] 2xl:w-auto h-auto object-contain max-w-xl"
            />
          </figure>
        </div>
      </div>
    </div>
  </article>
</section>

      <h2 className="text-center  text-lg font-bold md:text-2xl  lg:text-4xl 2xl:text-4xl font-display  md:mt-10 py-5">
      Our Conference
      </h2>
      <section className="flex hidden md:hidden lg:block justify-center 2xl:px-72 pb-10 lg:px-10">
        <div className="w-full mr-12">
          <div className="w-full mr-12 mt-5">
            <article className="ml-0 flex flex-col lg:flex-row mt-5 pt-1">
              <div className="lg:flex lg:items-stretch lg:w-1/2">
                <figure className="lg:hidden">
                  <img
                    src={conference}
                    alt="Our_Event"
                    className="w-full md:w-80 md:ml-56 ml-5 mb-5"
                  />
                </figure>

                <figure className="hidden lg:block lg:w-auto lg:mt-5 lg:mr-5 lg:flex-grow ">
                  <img
                    src={conference}
                    alt="Our_Event"
                    className="lg:w-[440px] lg:h-[550px] ml-32 2xl:ml-20 rounded-xl  "
                  />
                </figure>
              </div>

              <article className="w-full md:ml-12 lg:w-1/2 lg:ml-28 lg:flex lg:flex-col   lg:mt-20  2xl:mt-10 lg:flex-grow">
                <p className="text-xs ml-5 font-display lg:text-sm 2xl:text-base mb-8">
                At Random Walk, our AI conferences aren’t just gatherings—they set the stage for innovation and collaboration. Our approach is built on three core pillars:
                </p>
                <h2 className="text-xs ml-5  font-bold lg:font-display lg:text-base 2xl:text-xl  lg:pb-2 text-left">
                01. Pioneering Innovation
                </h2>
                <p className="text-xs ml-5  lg:font-display lg:text-sm lg:pb-8 2xl:text-base">
                This conference delves into AI’s transformative impact on media,rom content creation to audience engagement and ethical challenges.
                </p>
                <h2 className="text-xs ml-5 font-bold lg:font-display lg:text-base  2xl:text-cl lg:pb-2 text-left 2xl:text-xl">
                02. Knowledge & Collaboration
                </h2>
                <p className="text-xs ml-5  lg:font-display lg:text-sm lg:pb-8 2xl:text-base ">
                We bring together leading experts, researchers, and industry professionals to discuss AI’s evolving role in shaping the media landscape.
                </p>
                <h2 className="text-xs ml-5  font-bold lg:font-display lg:text-base lg:pb-2 text-left 2xl:text-xl">
                03. Impact-Driven AI
                </h2>
                <p className="text-xs ml-5 lg:font-display lg:text-sm 2xl:text-base">
                Our focus is on real-world applications, ensuring AI innovations lead to meaningful advancements in media, journalism, and communication.
                </p>
              </article>
            </article>
          </div>
        </div>
      </section>

      <section className="flex  lg:hidden justify-center 2xl:px-96 pb-10 lg:px-10">
        <article className="ml-0 flex flex-col md:flex-row  pt-1">
          <figure className="md:flex  md:w-1/2">
            <figure className="md:w-auto pb-5 md:pb-0   px-5 md:translate-y-5 object-cover lg:translate-y-0 lg:mt-5 md:pl-5 md:flex-grow">
              <img src={conference} alt="Our_Event" className=" rounded-xl" />
            </figure>
          </figure>

          <article className="w-full  md:w-1/2  md:flex md:flex-col   md:mt-12 md:flex-grow">
            <p className="text-xs  px-5  pb-3  md:px-0 font-display md:text-sm md:pb-6  ">
            At Random Walk, our AI conferences aren’t just gatherings—they set the stage for innovation and collaboration. Our approach is built on three core pillars:
            </p>
            <article className="   px-10 md:px-0 ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-4 text-left">
                {" "}
                01. Pioneering Innovation
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-4">
              This conference delves into AI’s transformative impact on media, from content creation to audience engagement and ethical challenges.
              </p>
            </article>

            <article className="px-10 md:px-0    ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-4 text-left">
                {" "}
                02. Knowledge & Collaboration
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-4">
              We bring together leading experts, researchers, and industry professionals to discuss AI’s evolving role in shaping the media landscape.
              </p>
            </article>

            <article className="px-10 md:px-0    ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-4 text-left">
                {" "}
                03. Impact-Driven AI
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-4">
              Our focus is on real-world applications, ensuring AI innovations lead to meaningful advancements in media, journalism, and communication.
              </p>
            </article>
          </article>
        </article>
      </section>

      {/* <section className="w-full px-5">
        <section className="max-w-[1240px] mx-auto md:pt-10">
          <div className="flex flex-col gap-1 px-4 text-[#666666]">
            {mediafutureData?.length > 0 ? (
              mediafutureData?.map((event, eventIndex) => {
                const eventImgs = event.Img || [];
                const redirectImgUrl = event.redirectImg?.url;
                const redirectLink = event.redirectLink;
                const videoUrl = event.Url;
                const videoId = videoUrl ? getYouTubeVideoId(videoUrl) : null;

                return (
                  <article key={event.id} className="relative md:px-16">
                    <h3 className="md:text-2xl text-xl lg:text-4xl font-display font-bold mb-4 text-center pb-1">
                      {event.Title}
                    </h3>

                    {videoId && (
                      <div className="mt-4 flex justify-center">
                        <iframe
                          width="1500"
                          height="500"
                          src={`https://www.youtube.com/embed/${videoId}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="YouTube video player"
                        ></iframe>
                      </div>
                    )}

                    <div className="grid grid-cols-1 pt-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {eventImgs.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`${image.url}`}
                          alt="Event"
                          className="w-full h-auto cursor-pointer"
                          onClick={() => openImageModal(eventIndex, imageIndex)}
                        />
                      ))}
                    </div>
                    <article className="flex justify-center">
                      <div className="flex justify-center mt-8 mb-8">
                        {redirectImgUrl && (
                          <NavLink to={redirectLink} className="mx-auto">
                            <img
                              src={`${redirectImgUrl}`}
                              alt="Redirect"
                              className="w-full h-auto"
                              style={{ maxWidth: "500px", cursor: "pointer" }}
                            />
                          </NavLink>
                        )}
                      </div>
                    </article>
                  </article>
                );
              })
            ) : (
              <p>No events available</p>
            )}

            {selectedEvent !== null && selectedImageIndex !== null && (
              <section className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
                <div className="">
                  <a
                    className="absolute top-2 right-2 cursor-pointer text-white text-5xl font-bold"
                    onClick={closeModal}
                  >
                    &times;
                  </a>

                  <a
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-5xl font-bold"
                    onClick={() => navigateImage(-1)}
                  >
                    &#8249;
                  </a>
                  <a
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-5xl font-bold"
                    onClick={() => navigateImage(1)}
                  >
                    &#8250;
                  </a>

                  <img
                    src={
                      mediafutureData[selectedEvent].Img[selectedImageIndex].url
                    }
                    alt="Popup"
                    className="object-contain"
                  />
                </div>
              </section>
            )}

            <h2 className="text-center text-[#666666] text-4xl font-bold md:py-1 font-display">
              Past mediaspeaker
            </h2>

            <section className="relative flex justify-center font-display pb-10">
              <a
                onClick={slideLeft}
                className="absolute rounded-full hover:bg-blue-400 -left-2 top-1/2 transform -translate-y-16 md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 hover:text-white text-blue-500 p-1 z-10"
                style={{
                  width: "3rem",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="rounded-full hover:bg-blue-400 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
              </a>

              <article className="carousel-container overflow-hidden w-full lg:max-w-[1200px] 2xl:max-w-[1200px] md:max-w-[1200px]">
                <figure
                  className="carousel-track flex transition-transform duration-300 ease-in-out"
                  style={{
                    transform: `translateX(-${
                      slideIndex * (carouselWidth / itemsPerView)
                    }px)`,
                  }}
                >
                  {mediaspeakerData?.map((speaker) => (
                    <figure
                      key={speaker.id}
                      className="flex-shrink-0"
                      style={{ width: `${carouselWidth / itemsPerView}px` }}
                    >
                      <div className="flex flex-col items-center p-2">
                        <img
                          src={`${speaker.speakerImg.url}`}
                          alt={speaker.speakerName}
                          className="md:w-56 md:h-60 lg:w-72 lg:h-96 object-cover mb-4"
                        />
                        <div className="md:text-xs lg:text-base text-xs font-semibold mb-1">
                          {speaker.speakerName}
                        </div>
                        <div className="flex text-xs md:text-xs text-center">
                          {`${speaker.speakerDesignation}, ${speaker.speakerCompany}`}
                        </div>
                      </div>
                    </figure>
                  ))}
                </figure>
              </article>

              <a
                onClick={slideRight}
                className="absolute hover:bg-blue-400 rounded-full -right-2 top-1/2 -translate-y-16 transform md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 text-blue-500 hover:text-white p-1 z-10"
                style={{
                  width: "3rem",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="rounded-full hover:bg-blue-400 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </a>

              <article className="absolute bottom-0 translate-y-4 left-0 right-0 flex justify-center mb-10">
                {Array.from({
                  length: Math.min(
                    Math.ceil((mediaspeakerData?.length ?? 0) / itemsPerView),
                    slideIndex + 1
                  ),
                }).map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 mt-5 rounded-full mx-1 ${
                      index === activeSlideIndex ? "bg-black" : "bg-gray-300"
                    }`}
                  ></div>
                ))}
              </article>
            </section>
          </div>
        </section>
      </section> */}

      <section className=" mt-20 ">
        <Popup utmContent={'events'} />
      </section>
    </section>
  );
};
export default Media;
