import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ThankYouPage = () => {
  const navigate = useNavigate();
  
  const handleBackToHome = () => {
    navigate('/');
  };
   useEffect(() => {
     if (!window.gtmInitialized) {
       const gtmScript = document.createElement('script');
       gtmScript.innerHTML = `
         (function(w,d,s,l,i){
           w[l]=w[l]||[];
           w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
           var f=d.getElementsByTagName(s)[0],
           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
           j.async=true;
           j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
           f.parentNode.insertBefore(j,f);
         })(window,document,'script','dataLayer','GTM-T7397TMR');
       `;
       document.head.appendChild(gtmScript);
   
       const gtmNoscript = document.createElement('noscript');
       const gtmIframe = document.createElement('iframe');
       gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-T7397TMR';
       gtmIframe.height = '0';
       gtmIframe.width = '0';
       gtmIframe.style.display = 'none';
       gtmIframe.style.visibility = 'hidden';
       gtmNoscript.appendChild(gtmIframe);
       document.body.prepend(gtmNoscript);
   
       // Mark GTM as initialized
       window.gtmInitialized = true;
   
       return () => {
         document.head.removeChild(gtmScript);
         document.body.removeChild(gtmNoscript);
       };
     }
   }, []);
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full text-center">
        <div className="mb-6">
          <svg
            className="w-16 h-16 mx-auto text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
        <h2 className="text-3xl font-bold mb-4">Thank You!</h2>
        <p className="text-gray-600 mb-6">Your request has been submitted successfully. We'll get back to you shortly.</p>
        <div className="flex justify-center">
          <button
            onClick={handleBackToHome}
            className="px-6 py-2 bg-[#C62B6D] text-white rounded hover:bg-[#B02561] transition-colors"
          >
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;