import React, { useState, useEffect ,useRef} from "react";
import { Client, Databases, ID } from "appwrite";
import backgroundImage from "../assets/Workshop/main.webp";
import square from "../assets/Workshop/square.webp";
import group2 from "../assets/Workshop/group2.webp";
import box from "../assets/Workshop/box.webp";
import line from "../assets/Workshop/line.webp";
import lady from "../assets/Workshop/lady.webp";
import icon1 from "../assets/Workshop/work1.webp";
import icon2 from "../assets/Workshop/work2.webp";
import icon3 from "../assets/Workshop/work3.webp";
import icon4 from "../assets/Workshop/work4.webp";
import workk10 from "../assets/Workshop/work10.webp";
import workk11 from "../assets/Workshop/work11.webp";
import workk12 from "../assets/Workshop/work12.webp";
import workk13 from "../assets/Workshop/work13.webp";
import workk14 from "../assets/Workshop/work14.webp";
import workk15 from "../assets/Workshop/work15.webp";
import workk16 from "../assets/Workshop/work16.webp";
import workk17 from "../assets/Workshop/work17.webp";
import workk18 from "../assets/Workshop/work18.webp";
import workk19 from "../assets/Workshop/work19.webp";
import workk20 from "../assets/Workshop/work20.webp";
import workk21 from "../assets/Workshop/work21.webp";
import iicon1 from "../assets/Workshop/iconn1.webp";
import iicon2 from "../assets/Workshop/iconn2.webp";
import iicon3 from "../assets/Workshop/iconn3.webp";
import iicon4 from "../assets/Workshop/iconn4.webp";
import topright from "../assets/Workshop/topright.webp";
import { Helmet } from "react-helmet-async";
import bottomright from "../assets/Workshop/bottomright.webp";
import blue from "../assets/Workshop/blue.webp";
import pink from "../assets/Workshop/pink.webp";
import pinkblue from "../assets/Workshop/pinkblue.webp";
import plus from "../assets/Workshop/plus.webp";
import minus from "../assets/Workshop/minus.webp";
import line1 from "../assets/Workshop/line1.webp";
import blur from "../assets/Workshop/blur.webp";
import girl2 from "../assets/Workshop/girl2.webp";
import Mission2 from "../assets/Workshop/Mission2.webp";
import paper2 from "../assets/Workshop/paper2.webp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import "./Workshop.css";

const Workshopduplicate = ({utmContent}) => {












  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessemail, setEmailId] = useState("");
  const [projectdetails, setprojectdetails] = useState("");

  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });

  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setfirstname("");
    setlastname("");
    setPhoneNumber("");

    setCompanyName("");
    setEmailId("");
    setprojectdetails("");
    setIsChecked(true);
    setErrors({});
  };
  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };


  // const scrollToConsultation = () => {
  //   // Determine which consultation section is currently visible based on screen size
  //   const isMobile = window.innerWidth < 768;
  //   const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
  //   const isDesktop = window.innerWidth >= 1024;
    
  //   let sectionId = '';
    
  //   if (isMobile) {
  //     sectionId = 'consultation-section-mobile';
  //   } else if (isTablet) {
  //     sectionId = 'consultation-section-tablet';
  //   } else if (isDesktop) {
  //     sectionId = 'consultation-section-desktop';
  //   }
    
  //   const sectionToScroll = document.getElementById(sectionId);
    
  //   if (sectionToScroll) {
  //     sectionToScroll.scrollIntoView({ behavior: 'smooth' });
  //   } else {
  //     console.error(`Section with ID ${sectionId} not found`);
  //   }
  // };


    const scrollToConsultation = () => {
      // Determine which consultation section is currently visible based on screen size
      const isMobile = window.innerWidth < 768;
      const isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
      const isDesktop = window.innerWidth >= 1024;
      
      let sectionId = '';
      
      if (isMobile) {
        sectionId = 'consultation-section-mobile';
      } else if (isTablet) {
        sectionId = 'consultation-section-tablet';
      } else if (isDesktop) {
        sectionId = 'consultation-section-desktop';
      }
      
      const sectionToScroll = document.getElementById(sectionId);
      
      if (sectionToScroll) {
        sectionToScroll.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Section with ID ${sectionId} not found`);
      }
    };
  
  
  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];
  
      const newUtmParams = {      utm_content: utmContent,};
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };
  
    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);
  
    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);

  const handleSubmitcontact = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!phoneNumber) {
        setErrors({ phoneNumber: "Phone number is required" });
        setIsLoading(false);
        return;
      }
      const fullNumber = phoneNumber.startsWith("+") ? phoneNumber : `+${phoneNumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        return;
      }
  
const formData={
  firstname,
  lastname,
  businessemail,
  companyName,
  phoneNumber,
  projectdetails,
  utmSource: utmParams.utm_source,
  utmMedium: utmParams.utm_medium,
  utmCampaign: utmParams.utm_campaign,
  utmId: utmParams.utm_id,
  utmTerm: utmParams.utm_term,
  utmContent: "ai-workshop-for-corporates", // Set the utmContent to "homepage"

}
      await Promise.all([
        databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID2,
        ID.unique(),
        formData
 
      ),
  
      // Then send to Express backend
 fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        
        body: JSON.stringify(formData),
      }).then (async(response) => {
        
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    })
  ]);
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };
  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
    resetForm();
  };


  const [isModalOpenbrochure, setIsModalOpenbrochure] = useState(false);
  const [isSuccessOpenbrochure, setIsSuccessOpenbrochure] = useState(false);
  const [fullnamebrochure, setfullnamebrochure] = useState('');
  const [jobtitlebrochure, setjobtitlebrochure] = useState('');
  const [companynamebrochure, setcompanynamebrochure] = useState('');
  const [countrybrochure, setcountrybrochure] = useState('');
  const [emailbrochure, setemailbrochure] = useState('');
  const [utmSourcebrochure, setUtmSourcebrochure] = useState('');
  const [utmMediumbrochure, setUtmMediumbrochure] = useState('');
  const [utmCampaignbrochure, setUtmCampaignbrochure] = useState('');
  const [utmIdbrochure, setUtmIdbrochure] = useState('');
  const [utmTermbrochure, setUtmTermbrochure] = useState('');
  const [utmContentbrochure, setUtmContentbrochure] = useState('ai-workshop-for-corporates');
  const [errorsbrochure, setErrorsbrochure] = useState({});

  const resetFormdownload = () => {
    setfullnamebrochure('');
    setjobtitlebrochure('');
    setcompanynamebrochure('');
    setcountrybrochure('');
    setemailbrochure('');
    setIsChecked(true);
    setErrorsbrochure({});
  };

  const clientbrochure = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesbrochure = new Databases(client);

  const handleButtonClickbrochure = () => {
    setIsModalOpenbrochure(true);
    resetFormdownload();
  };

  const closeModalbrochure = () => {
    setIsModalOpenbrochure(false);
    resetFormdownload();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setUtmSourcebrochure(searchParams.get('utm_source') || '');
    setUtmMediumbrochure(searchParams.get('utm_medium') || '');
    setUtmCampaignbrochure(searchParams.get('utm_campaign') || '');
    setUtmIdbrochure(searchParams.get('utm_id') || '');
    setUtmTermbrochure(searchParams.get('utm_term') || '');
    setUtmContentbrochure('ai-workshop-for-corporates');
  }, []);

  const downloadPDFbrochure = () => {
    const pdfUrl =
      'https://devrw.blob.core.windows.net/rw-website/assets/AI_Mastery_for_Healthcare_Brochure_3e619b6625.pdf';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('target', '_blank');
    link.setAttribute('download', 'AI_Mastery_for_Healthcare_Brochure.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const handleSubmitbrochure = async (event) => {
    event.preventDefault();
  const formData={
    fullnamebrochure,
          jobtitlebrochure,
          companynamebrochure,
          countrybrochure,
          emailbrochure,
          utmSourcebrochure,
          utmMediumbrochure,
          utmCampaignbrochure,
          utmIdbrochure,
          utmTermbrochure,
          utmContentbrochure,
  }
    try {
      // Submit form data to Appwrite
      await databasesbrochure.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID3,
        ID.unique(),
        formData
     
      );

      // Send email to the user
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-brochure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }

      // Trigger the PDF download
      downloadPDFbrochure();

      // Handle success (optional)
      setIsSuccessOpenbrochure(true);
      setIsModalOpenbrochure(false);
      resetFormdownload();
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorsbrochure({ submit: 'Error submitting form. Please try again.' });
    }
  };





  const [isSuccessOpenconsultation, setIsSuccessOpenconsultation] =
    useState(false);

  const [fullnameconsultation, setfullnameconsultation] = useState("");

  const [emailconsultation, setemailconsultation] = useState("");

  const [phonenumberconsultation, setphonenumberconsultation] = useState("");

  const [utmSourceconsultation, setUtmSourceconsultation] = useState('');
  const [utmMediumconsultation, setUtmMediumconsultation] = useState('');
  const [utmCampaignconsultation, setUtmCampaignconsultation] = useState('');
  const [utmIdconsultation, setUtmIdconsultation] = useState('');
  const [utmTermconsultation, setUtmTermconsultation] = useState('');
  const [utmContentconsultation, setUtmContentconsultation] = useState('ai-workshop-for-corporates');

  const [errorsconsultation, setErrorsconsultation] = useState({});

  const resetFormconsultation = () => {
    setfullnameconsultation("");
    setemailconsultation("");
    setphonenumberconsultation("");

    setIsChecked(true);
    setErrorsconsultation({});
  };
  const handleButtonClickconsultation = () => {
    setIsModalOpen(true);
    resetFormconsultation();
  };

  const closeModalconsultation = () => {
    setIsModalOpen(false);
    resetFormconsultation();
  };

  const clientconsultation = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesconsultation = new Databases(client);

  const handlePhoneNumberChangeconsultation = (number, country) => {
    setphonenumberconsultation(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrorsconsultation((prev) => ({
            ...prev,
            phonenumberconsultation: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrorsconsultation((prev) => ({
            ...prev,
            phonenumberconsultation: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrorsconsultation((prev) => ({
            ...prev,
            phonenumberconsultation: "",
          }));
        }
      } else {
        setErrorsconsultation((prev) => ({
          ...prev,
          phonenumberconsultation: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrorsconsultation((prev) => ({
        ...prev,
        phonenumberconsultation: "Please enter a valid phone number",
      }));
    }
  };

 
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setUtmSourceconsultation(searchParams.get('utm_source') || '');
    setUtmMediumconsultation(searchParams.get('utm_medium') || '');
    setUtmCampaignconsultation(searchParams.get('utm_campaign') || '');
    setUtmIdconsultation(searchParams.get('utm_id') || '');
    setUtmTermconsultation(searchParams.get('utm_term') || '');
    setUtmContentconsultation('ai-workshop-for-corporates');
  }, []);




  const handleSubmitconsultation = async (event) => {
    event.preventDefault();
    setIsLoading(true);
 const formData={


  fullnameconsultation,
          phonenumberconsultation,
          emailconsultation,
          utmSourceconsultation,
          utmMediumconsultation,
          utmCampaignconsultation,
          utmIdconsultation,
          utmTermconsultation,
          utmContentconsultation,

 }

    try {
      const fullNumber = phonenumberconsultation.startsWith("+")
        ? phonenumberconsultation
        : `+${phonenumberconsultation}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrorsconsultation((prev) => ({
          ...prev,
          phonenumberconsultation: "Please enter a valid phone number", // Changed from phoneNumber to phonenumberint
        }));
        return;
      }
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID4,
        ID.unique(),
        {
          fullnameconsultation,
          phonenumberconsultation,
          emailconsultation,
          utmSourceconsultation,
          utmMediumconsultation,
          utmCampaignconsultation,
          utmIdconsultation,
          utmTermconsultation,
          utmContentconsultation,
        }
      );
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-consultation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }

      setIsModalOpen(false);
      setIsSuccessOpenconsultation(true);
      resetFormconsultation();
    }catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };
  const closeSuccessModalconsultation = () => {
    setIsSuccessOpenconsultation(false);
  };




  const [selectedImage, setSelectedImage] = useState(icon1);

  const [lastX, setLastX] = useState(null);

  const handleMouseMove = (e) => {
    const container = document.getElementById("scroll-container");

    if (lastX !== null) {
      const movement = e.clientX - lastX;
      if (movement < 0) {
        container.scrollLeft += Math.abs(movement) * 2;
      }
    }

    setLastX(e.clientX);
  };
  const [buttonText, setButtonText] = useState({
    work1: "",
    work2: "",
    work3: "",
    work4: "",
  });
  const [buttonIcon, setButtonIcon] = useState({
    work1: iicon1,
    work2: iicon2,
    work3: iicon3,
    work4: iicon4,
  });
  const [textVisibility, setTextVisibility] = useState({
    work1: true,
    work2: false,
    work3: false,
    work4: false,
  });
  const [activeWorkshop, setActiveWorkshop] = useState("work1");

  const handleButtonClick = (workshop) => {
    setTextVisibility((prevState) => ({
      ...Object.fromEntries(
        Object.keys(prevState).map((key) => [
          key,
          key === workshop.id ? !prevState[key] : false,
        ])
      ),
    }));

    setActiveWorkshop(workshop.id === activeWorkshop ? null : workshop.id);

    setButtonText((prevState) => ({
      ...prevState,
      [workshop.id]: !prevState[workshop.id] ? workshop.text : "",
    }));

    setSelectedImage(workshop.image);
  };

  const workshops = [
    {
      id: "work1",
      image: icon1,
      buttonText: "Industry Specific",
      textBelow: (
        <div>
          <div className="hidden md:block lg:mt-3 text-left ">
            Strategic implications of AI for your <br />
            specific industry and organization
          </div>
          <div className="block md:hidden lg:mt-3 text-left ">
            Strategic implications of AI for your specific industry and
            organization
          </div>
        </div>
      ),
    },
    {
      id: "work2",
      image: icon2,
      buttonText: "Competitive Advantage",
      textBelow: (
        <div>
          <div className=" hidden md:block lg:mt-3 text-left  ">
            Make informed decisions that drive <br />
            competitive advantage
          </div>
          <div className=" block md:hidden lg:mt-3  text-left ">
            Make informed decisions that drive competitive advantage
          </div>
        </div>
      ),
    },
    {
      id: "work3",
      image: icon3,
      buttonText: "AI Driven Initiatives",
      textBelow: (
        <div>
          <div className="hidden md:block lg:mt-3 text-left ">
            Drive impactful AI-driven initiatives within <br />
            your department
          </div>
          <div className="md:hidden block lg:mt-3 text-left ">
            Drive impactful AI-driven initiatives within your department
          </div>
        </div>
      ),
    },
    {
      id: "work4",
      image: icon4,
      buttonText: "AI-Ready Culture",
      textBelow: (
        <div>
          <div className="hidden md:block  lg:mt-3 text-left ">
            Cultivate an AI-ready Culture within your
            <br /> team, ensuring seamless AI integration
          </div>
          <div className="md:hidden block lg:mt-3 text-left ">
            Cultivate an AI-ready Culture within your team, ensuring seamless AI
            integration
          </div>
        </div>
      ),
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href =
      "https://randomwalk.ai/wp-content/uploads/2024/01/Random-Walk-Common-AI-Brochure-v3.pdf";
  };
  const Dropdown = ({ question, answer, image, isOpen, onClick }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);
  
    React.useEffect(() => {
      if (contentRef.current) {
        setHeight(contentRef.current.scrollHeight);
      }
    }, [answer]);
  
    return (
      <div className="my-4 relative">
        <div className={` rounded-lg overflow-hidden ${isOpen ? 'bg-white' : ''}`}>
          <a
            className={`bg-indigo-100 w-full text-xs 2xl:text-lg text-left font-display md:text-sm lg:text-sm p-2 md:py-4 md:px-4 hover:bg-indigo-200 hover:text-[#666666] flex justify-between items-center cursor-pointer transition-colors duration-300 ${
              isOpen ? 'bg-indigo-200 rounded-t-lg' : 'rounded-lg'
            }`}
            onClick={onClick}
          >
            <h2>{question}</h2>
            <img
              src={isOpen ? minus : plus}
              alt="Toggle Icon"
              className={`ml-2 w-6 h-6 transition-transform duration-300 ${
                isOpen ? 'rotate-180' : ''
              }`}
            />
          </a>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out`}
            style={{ maxHeight: isOpen ? `${height}px` : '0' }}
          >
            <div
              ref={contentRef}
              className="text-xs bg-gray-100 2xl:text-lg py-6 px-4 font-display md:text-sm lg:text-sm"
            >
              <p className="opacity-100 transition-opacity duration-300">{answer}</p>
            </div>
          </div>
        </div>
      </div>
    
    );
  };


  const DropdownList = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const dropdowns = [
      {
        question:
          "What sets Executive AI Workshops apart from other training programs?",
        answer:
          "Our executive AI workshops blend cutting-edge expertise from industry leaders with hands-on learning, ensuring participants gain practical knowledge. Through AI training, attendees learn to effectively apply AI concepts in real-world business scenarios. ",
        image: "arrowdown.png",
      },
      {
        question:
          "Are the AI training workshops tailored to specific industries or sectors?",
        answer:
          "Yes, our AI training workshops are adaptable to various industries and sectors. We provide customized insights and case studies relevant to participants' domains, ensuring maximum applicability. Through our corporate AI training and development programs, we ensure the content is directly aligned with the unique needs of each sector.",
        image: "arrowdown.png",
      },
      {
        question:
          "Is technical knowledge required to participate in the AI training workshops?",
        answer:
          "No, technical knowledge is not required. Our AI leadership training workshops focus on demonstrating the business value of AI and exploring AI tools without the need for coding. AI training for executives ensures practical learning for all participants, regardless of their technical background.",
        image: "arrowdown.png",
      },
      {
        question: "Are the AI training workshops offered globally?",
        answer:
          "Yes, our AI leadership training workshops are available globally. We offer both online AI training and on-site AI training options, to accommodate participants from around the world. Our executive AI workshops and AI  training are designed to be accessible regardless of location.",
        image: "arrowdown.png",
      },
      {
        question: "Can the AI training be conducted both online and on-site?",
        answer:
          "Yes, our AI leadership training workshops are flexible and can be conducted either online or on-site, based on your organization’s preferences. We tailor our online AI training as well as on-site AI training programs to ensure the best fit for your team’s needs.",
        image: "arrowdown.png",
      },
      {
        question: "Will I get hands-on experience with AI tools?",
        answer:
          "Yes, our corporate AI training programs for executives and professionals include guided exercises using real-world AI tools and platforms.",
        image: "arrowdown.png",
      },
      {
        question:
          "What is the minimum number of participants required for the AI training workshop?",
        answer:
          "We require a minimum of 10 participants from organizations to conduct our AI training workshops effectively.",
        image: "arrowdown.png",
      },
    ];

    return (
     < div className="text-[#666666]">
      {dropdowns.map((dropdown, index) => (
        <Dropdown
          key={index}
          question={dropdown.question}
          answer={dropdown.answer}
          image={dropdown.image}
          isOpen={index === openIndex}
          onClick={() => setOpenIndex(index === openIndex ? null : index)}
        />
      ))}
    </div>
    );
  };





  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image";
    link.href = backgroundImage; // Image source
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link); // Cleanup on component unmount
    };
  }, []);


  useEffect(() => {
    // Set page title
    document.title = " Random Walk AI Workshops| AI Training for Executives & Team Development";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Build a strong AI foundation with Random Walk's executive AI training workshops. Equip your team to lead AI initiatives, drive innovation, and enhance operational efficiency.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Build a strong AI foundation with Random Walk's executive AI training workshops. Equip your team to lead AI initiatives, drive innovation, and enhance operational efficiency.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', " Random Walk AI Workshops| AI Training for Executives & Team Development");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Build a strong AI foundation with Random Walk's executive AI training workshops. Equip your team to lead AI initiatives, drive innovation, and enhance operational efficiency.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/ai-workshop-for-corporates/";
  }, []);
  return (
    <>
      {/* <Helmet>
        <title>
          Random Walk AI Workshops| AI Training for Executives & Team Development
        </title>
        <meta
          property="og:title"
          content="Random Walk AI Workshops| AI Training for Executives & Team Development"
        />
        <meta
          name="description"
          content="Build a strong AI foundation with Random Walk's executive AI training workshops. Equip your team to lead AI initiatives, drive innovation, and enhance operational efficiency."
        />
        <meta
          property="og:description"
          content="Build a strong AI foundation with Random Walk's executive AI training workshops. Equip your team to lead AI initiatives, drive innovation, and enhance operational efficiency."
        />
        <link
          rel="canonical"
          href="https://randomwalk.ai/ai-workshop-for-corporates/"
        />
      </Helmet> */}

    <section
  className="relative h-64 md:h-screen flex justify-center items-center bg-cover bg-center mt-16 md:mt-0"
  loading="eager"
  fetchpriority="high"
  style={{ backgroundImage: `url(${backgroundImage})` }}
>
  <article className="absolute inset-0 bg-[#3E59B7] opacity-50"></article>
  <article className="relative z-10 text-center text-white mt-0 md:mt-0 lg:mt-0">
    <h1 className="text-xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl mt-0 md:mt-10 font-bold font-display text-white lg:mb-4">
      Corporate Executive AI Workshops
    </h1>
    <p className="md:mt-5 text-[10px] md:text-[20px] lg:text-[30px] font-display">
      Bridge the gap between AI Hype and business impact.
    </p>
    <button 
            onClick={scrollToConsultation}
            className="mt-6 px-6 py-2 border-2 bg-bo text-white rounded-lg font-semibold hover:bg-opacity-90 hover:bg-white hover:text-black transition-all duration-300 text-sm md:text-base"
          >
           Schedule Consultation
          </button>
  </article>
</section> 

      <section className=" p-4 md:p-8  md:px-20 2xl:px-32 bg-white  text-left  ">
        <p className="text-xs md:text-sm lg:text-base font-display 2xl:text-lg 2xl:font-normal text-[#666666]">
          In today's dynamic business environment, ignoring AI is not an option.
          AI is transforming every industry. You see the headlines boasting AI's
          potential, but translating that hype into tangible benefits for your
          company can feel daunting. You might be asking yourself:
        </p>
        <p className="mt-2 md:mt-4 text-xs md:text-sm 2xl:text-base font-display text-customGray lg:text-base 2xl:text-lg 2xl:font-normal text-[#666666]">
          Where do I even begin? How do I navigate the complex world of AI
          technologies and build a robust AI strategy for decision-makers ?
        </p>

        <p className="mt-2 md:mt-4 text-xs md:text-sm lg:text-base xl:text-base 2xl:text-lg 2xl:font-normal font-display font-semibold text-[#C62B6D]  lg:text-base">
          You're not alone! Many business leaders face the same challenges.
          That's where we come in: AI training for executives.
        </p>
        <p className="mt-2 md:mt-4 text-xs md:text-sm 2xl:text-base font-display text-customGray lg:text-base 2xl:text-lg 2xl:font-normal text-[#666666]">
          With specialized AI business strategy courses and AI training
          , we help corporate leaders gain the skills you need to drive AI
          initiatives with confidence.
        </p>
      </section>
      <section className="relative w-full max-w-screen-lg mt-10 lg:ml-2 2xl:ml-56 pb-0">
        <article className="flex justify-center items-start ">
          <aside className="flex flex-col items-center relative z-10 ">
            {workshops.map((workshop) => (
              <button
                key={workshop.id}
                className={`lg:ml-0 md:ml-16 2xl:ml-80 font-display z-20 text-[#666666] text-xs  md:py-8 lg:py-10 py-3 pr-6  md:pr-10 lg:pr-10 px-2 md:px-10 lg:px-12 md:mt-4 lg:mt-2 mt-2  rounded flex text-left md:text-center md:items-center border-2 border-white transition-transform duration-600 ease-in-out ${
                  activeWorkshop === workshop.id
                    ? ` bg-[#FFFFFF] scale-105 shadow-xl w-7/12 md:w-11/12 opacity-70 border-2 border-white z-10 mr-32 md:mr-0` // Increased border width
                    : `bg-transparent shadow md:w-3/4 w-1/2 md:mr-32  mr-44 border-2 border-white   z-10`
                }`}
                onClick={() => handleButtonClick(workshop)}
              >
                <div className="flex items-center font-display flex-col">
                  <div className="flex items-center">
                    <img
                      src={buttonIcon[workshop.id]}
                      alt={`Icon ${workshop.id}`}
                      className="w-7 h-7 md:mr-2  "
                    />
                    <span className="text-pink-600 font-bold lg:text-sm text-xs md:mr-18 2xl:text-base">
                      {workshop.buttonText}
                    </span>
                  </div>
                  {textVisibility[workshop.id] && (
                    <span className="text-[#666666] lg:text-sm text-xs font-normal 2xl:text-base  ">
                      {workshop.textBelow}
                    </span>
                  )}
                </div>
              </button>
            ))}
          </aside>
          <figure
            className="absolute top-0 right-0 lg:relative md:static"
            style={{ marginLeft: "-50px" }}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="AI_Training_for_Executives_img1 "
                className="w-44 h-72  md:w-auto md:h-auto 2xl:ml-40 z-50 object-cover"
              />
            )}
          </figure>
          
        </article>

<div className="py-10 flex items-center justify-center">
  <button
    onClick={scrollToConsultation}
    className="flex px-4 py-1.5 bg-[#C62B6D] border-2 border-gray-300 text-white rounded-lg font-semibold shadow-md transition-all duration-300 text-base items-center justify-center hover:bg-white hover:text-black hover:border-gray-800"
  >
    Schedule Consultation
  </button>
</div>
    
        <img
          src={blur}
          alt="AI_Training_for_Executives_img2"
          className="absolute -bottom-44 -left-32 w-auto h-16 md:w-24 md:h-24 lg:w-96 lg:h-96"
        />
      </section>

      <section className=" hidden md:block relative lg:h-screen md:h-full 2xl:h-fit flex flex-col justify-start bg-custom-gradient-workshop1 bg-cover bg-center  lg:px-10 ">
        <article className="pt-16 md:px-8 2xl:px-16 2xl:text-center  md:text-left text-white">
          <h1 className="text-md lg:text-xl 2xl:text-2xl font-medium font-display text-white">
            We understand navigating AI can feel complex. That’s why our
            corporate AI trainings apply the following first principles:
          </h1>
        </article>

        <article className="mt-3 2xl:mt-20 md:px-4 lg:px-8 flex  justify-between gap-2 2xl:pb-20 ">
          <article className="md:w-44 lg:w-1/4   h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full  md:h-20 2xl:h-72 lg:h-40  ">
              <figure className="flex justify-center items-center">
                <img
                  src={girl2}
                  alt="Industry Experts"
                  className="md:w-32 lg:w-56 2xl:w-80 object-cover"
                />
              </figure>
            </div>
            <h2 className="md:text-md lg:text-lg font-medium  text-left font-display mt-10 2xl:text-xl 2xl:mt-0  2xl:text-left mr-16">
              Industry Experts
            </h2>
            <p className="font-display md:text-md lg:text-lg 2xl:text-xl">
              Led by industry experts who speak your language and understand
              your challenges.
            </p>
          </article>

          <article className="md:w-44  lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              <figure className="flex justify-center items-center">
                <img
                  src={paper2}
                  alt="Engagement"
                  className="  md:w-32 lg:w-56 2xl:w-80 object-cover"
                />
              </figure>
            </div>
            <h2 className="md:text-md lg:text-lg font-medium font-display mt-10 mr-24 text-left 2xl:text-left 2xl:mt-0 2xl:text-xl">
              Engagement
            </h2>
            <p className="md:text-md  font-display lg:text-lg 2xl:text-xl">
              Highly interactive and engaging, fostering collaboration and
              knowledge sharing.
            </p>
          </article>

          <article className="md:w-44 lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              <figure className="flex justify-center items-center">
                <img
                  src={group2}
                  alt="Real-world Application"
                  className=" md:w-32 lg:w-56 2xl:w-80 object-cover"
                />
              </figure>
            </div>
            <h2 className="lg:text-lg md:text-md font-medium 2xl:text-xl font-display mt-10 text-left 2xl:mt-0  2xl:text-left lg:mr-1 md:mr-0">
              Real-world Application
            </h2>
            <p className="md:text-md font-display 2xl:text-xl lg:text-lg">
              Designed for real world application, with actionable insights you
              can implement immediately.
            </p>
          </article>

          <article className="md:w-44 lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              <figure className="flex justify-center items-center">
                <img
                  src={Mission2}
                  alt="Strategic Guidance"
                  className="md:w-32 lg:w-56  2xl:w-80 object-cover"
                />
              </figure>
            </div>
            <h2 className="md:text-md lg:text-lg font-medium font-display text-left 2xl:text-left mt-10 2xl:mt-0 mr-10 2xl:text-xl">
              Strategic Guidance
            </h2>
            <p className="md:text-md font-display lg:text-lg 2xl:text-xl">
              Our AI workshop helps business leaders align AI initiatives with
              business objectives.
            </p>
          </article>
        </article>

      
       <div className="relative z-10 flex justify-center mb-8  2xl:mt-0  md:mt-10 md:pb-10 2xl:pb-16 lg:mt-5 ">
        <button 
          onClick={scrollToConsultation}
          type="button"
          className="px-6 py-2 border-2 text-white rounded-lg font-semibold hover:bg-opacity-90 hover:bg-white hover:text-black transition-all duration-300 text-sm md:text-base cursor-pointer"
          style={{ pointerEvents: 'auto' }}
        >
        Schedule Consultation
        </button>
      </div>
      </section>

      <section className="block md:hidden relative h-5/6  flex flex-col justify-start bg-custom-gradient-workshop1  bg-cover bg-center">
        <article className="pt-16 px-4 md:px-8 text-center text-white">
          <h1 className="text-sm lg:text-lg font-semibold font-display text-white ">
            We understand navigating AI can feel complex. That’s why our
            corporate AI trainings apply the following first principles:
          </h1>
        </article>

        <article
          id="scroll-container"
          className="mt-10 px-8 flex  gap-4 overflow-x-auto scroll-smooth pb-10 md:pb-44"
          onMouseMove={handleMouseMove}
        >
          <article className="  w-7/12  bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <figure>
              <img
                src={girl2}
                alt=" Industry Experts"
                className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
              />
            </figure>

            <h2 className="text-sm font-medium  text-left font-display mt-10 ">
              Industry Experts
            </h2>
            <p className="font-display text-sm">
              Led by industry experts who speak your language and understand
              your challenges.
            </p>
          </article>

          <article className="w-7/12     bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <figure className="hidden static">
              <img
                src={square}
                alt="Engagement_img1"
                className="absolute top-44 right-2 w-10 h-10 object-cover"
              />
            </figure>
            <img
              src={paper2}
              alt="Engagement_img2"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 text-left">
              Engagement
            </h2>
            <p className="font-display text-sm mb-5">
              Highly interactive and engaging, fostering collaboration and
              knowledge sharing.
            </p>
          </article>

          <article className="w-7/12   bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <img
              src={group2}
              alt="Real-world Application"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 text-left ">
              Real-world Application
            </h2>
            <p className="font-display text-sm">
              Designed for real-world application, with actionable insights you
              can implement immediately.
            </p>
          </article>

          <article className="w-7/12     bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <img
              src={Mission2}
              alt=" Strategic Guidance"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 mr-3 text-left">
              Strategic Guidance
            </h2>
            <p className="font-display text-sm mb-6">
              Our AI workshop helps business leaders align AI initiatives with
              business objectives.
            </p>
          </article>

        </article>
        <div className="relative z-10 flex justify-center mb-8 ">
        <button 
          onClick={scrollToConsultation}
          type="button"
          className="px-6 py-2 border-2 text-white rounded-lg font-semibold hover:bg-opacity-90 hover:bg-white hover:text-black transition-all duration-300 text-sm md:text-base cursor-pointer"
          style={{ pointerEvents: 'auto' }}
        >
         Schedule Consultation
        </button>
      </div>
        
      </section>

      <section className=" flex flex-col md:flex-row bg-white ">
        <article className="flex-1 flex items-center justify-left  md:mb-44 lg:mb-64">
          <img
            src={box}
            alt="Who_is_this_program_tailored_for_img1"
            className="md:w-72 h-auto  w-44 object-cover"
          />
          <img
            src={lady}
            alt="Who_is_this_program_tailored_for_img2"
            className="hidden md:block absolute  w-9/12 md:w-8/12 lg:w-7/12 2xl:w-5/12  md:mt-80 lg:mt-96 lg:pt-28 lg:ml-16 "
          />
          <img
            src={lady}
            alt="Who_is_this_program_tailored_for_img3"
            className="block md:hidden absolute  mt-32 lg:mt-96 lg:pt-28  pl-20  "
          />
          <header className="absolute   bg-[#5D5DFF] pt-3 pr-8 md:pr-8 pl-2 pb-3 md:ml-12 lg:ml-20 mb-32">
            <p className="text-sm  lg:text-2xl text-left font-bold font-display  text-white ">
              WHO IS THIS PROGRAM <br />
              TAILORED FOR?
            </p>
          </header>
        </article>

        <article className="flex-1  items-start   md:mt-32  lg:mt-24 2xl:mt-16  lg:mr-16 2xl:mr-80 px-4 md:px-0 mt-10">
          <h1 className="md:text-sm lg:text-[15.55px] font-bold 2xl:text-lg  font-display text-[#C62B6D] ">
            C-Suite Executives
          </h1>
          <p className="md:text-[12px] lg:text-[15.55px] font-display 2xl:text-base  text-[#666666] mt-2 leading-tight text-xs">
            Chart a clear course for your organization's AI journey, make
            informed strategic decisions, and position your company at the
            forefront of innovation. By understanding the business value of AI
            and how it aligns with your goals, you'll be better equipped to
            drive growth and maintain a competitive edge.
            <br />
            <br /> Includes Chief Executive Officer, President, Managing
            Director, Chief Financial Officer, Chief Operating Officer, Chief
            Technology Officer, Chief Human Resources Officer.
          </p>

          <figure className="w-auto mt-8 mx-auto relative">
            <img src={line1} alt="line1" className="w-auto h-0.5" />
          </figure>
          <h1 className="md:text-sm lg:text-[15.55px] font-bold mt-8 font-display  2xl:text-lg  text-[#C62B6D]  ">
            Business Function Heads
          </h1>
          <p className=" md:text-[12px] lg:text-[15.55px] font-display  2xl:text-base  text-[#666666] mt-2 leading-tight text-xs">
            Whether you're in marketing, sales, operations, HR, or IT, our AI
            workshops equip you with the insights and tools needed to leverage
            AI for maximum impact within your department. From optimizing
            processes to enhancing customer experiences, we've got you covered.
            <br />
            <br /> Vice President, Director, Heads of Departments.
          </p>
          <figure className="w-auto mt-8 mx-auto relative">
            <img src={line1} alt="line2" className="w-auto h-0.5" />
          </figure>

          <h1 className="md:text-sm lg:text-[15.55px] font-bold mt-6  2xl:text-lg font-display text-[#C62B6D] ">
            Senior Managers
          </h1>
          <p className="md:text-[12px] lg:text-[15.55px] font-display  2xl:text-base text-[#666666] mt-3 leading-tight text-xs mb-10 md:mb-0 ">
            Drive organizational change from within. Our AI training for
            managers will provide you with the knowledge and resources to
            spearhead AI initiatives, foster a culture of innovation, and
            empower your team to embrace the future with confidence.
          </p>
          <div className="relative z-10 flex justify-start mb-8 sm:mt-4 ">
        <button 
          onClick={scrollToConsultation}
          type="button"
          className="px-6 py-2 border-2 text-white bg-[#C62B6D] rounded-lg font-semibold hover:bg-opacity-90 hover:bg-white hover:text-black transition-all duration-300 text-sm md:text-base cursor-pointer hover:border-gray-800"
          style={{ pointerEvents: 'auto' }}
        >
     Schedule Consultation
        </button>
      </div>
        </article>

        
      </section>

      <section className="hidden md:block relative h-full flex flex-col justify-start bg-[#E6EBF8] ">
        <header className="pt-8 lg:px-0 md:text-left  2xl:ml-56 items-start ml-10 ">
          <h1 className="md:text-2xl lg:text-5xl font-bold font-display text-[#666666]">
            Choose Your Program
          </h1>
        </header>

        <article className="lg:justify-center flex flex-col  md:flex-row md:px-2 lg:px-0">
          <article className="bg-white md:w-64 lg:w-96 2xl:w-3/12 h-5/6 mt-10 m-6   rounded-2xl shadow-lg   ">
            <img
              src={workk10}
              alt="AI Awareness Workshop"
              className="  mt-[-10px]"
            />
            <div className="overflow-auto h-72  mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm lg:mt-10  pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold ">Duration:</span>{" "}
                  <span className="text-[#666666]">3 hours</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">General AI awareness</span>
                  <br />
                  <br />
                </span>
                <p className="lg:font-display text-[15.55px] text-[#666666] leading-tight">
                  In just three hours, our AI awareness workshop provides your
                  team with a comprehensive introduction to AI, creating a solid
                  foundation for further exploration. Perfect for busy
                  professionals and business executives seeking an impactful,
                  quick dive into AI training concepts, this AI workshop is
                  designed to support AI upskilling and future AI-driven
                  initiatives within your organization.
                </p>
              </p>
            </div>

            <div className="flex justify-center w-full  ">
              <button
                className="lg:mt-20 mt-2 mb-12 lg:mb-14 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg "
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>

          <article className="bg-white md:w-64 lg:w-96 h-5/6 2xl:w-3/12  mt-10  lg:mr-5 mb-4 lg:mb-4  rounded-2xl shadow-lg ">
            <img
              src={workk11}
              alt="AI Fundamentals Workshop"
              className=" mt-[-10px]  "
            />
            <div className="overflow-auto h-72  mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm mt-3 pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">1 day or 2 half days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Build a solid foundational understanding of AI’s concepts
                    and applications.
                  </span>
                  <br />
                  <br />
                </span>
                <p className="lg:font-display text-[#666666] lg:text-[15.55px] leading-tight">
                  Gain a foundational understanding of AI concepts and
                  applications with our AI Fundamentals workshop. Covering the
                  basics of AI for business leaders, this session explores AI’s
                  transformative potential, real-world applications, hands-on
                  experiences, ethical considerations, and organizational
                  opportunities.
                  <br/>
                  <br />
                  Through this workshop, participants gain insight into AI’s
                  impact on their roles, fostering a culture of continuous
                  learning, innovation, and cross-functional collaboration. By
                  empowering staff with AI knowledge, the workshop lays a solid
                  foundation for future AI-driven initiatives, contributing to
                  organizational growth and adaptation in the rapidly evolving
                  AI landscape.
                  <br />
                </p>
              </p>
            </div>

            <div className="flex justify-center w-full">
              <button
                className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>

          <article className="bg-white  md:w-64 lg:w-96 h-5/6 2xl:w-3/12  mt-10  lg:mr-5 mb-4 lg:mb-4  ml-4 rounded-2xl shadow-lg">
            <img
              src={workk12}
              alt="Applied AI Workshop for Business Leaders"
              className=" mt-[-10px]"
            />
            <div className="overflow-auto h-72 mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm mt-3 pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">2 days </span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    {" "}
                    Strategic application of AI in business
                  </span>
                  <br />
                  <br />
                </span>
                <span className="lg:font-display lg:text-[15.55px] ">
                  <span className="font-bold text-[#666666]">
                    Day 1 focuses on the strategic application of AI in
                    business, equipping C-level executives with a fundamental
                    understanding of AI and machine learning basics.
                    <br />
                    <br />{" "}
                  </span>
                  <p className="text-[#666666] leading-tight">
                    This corporate AI training workshop allows participants
                    discover how AI optimizes decision-making, enhances user
                    experiences, and boosts operational efficiency. The session
                    also covers data quality as a crucial factor in AI success
                    and explores ethical considerations for responsible AI
                    adoption.
                  </p>{" "}
                  <br />
                  <br />
                  <span className="font-bold text-[#666666]">
                     Day 2 shifts focus to aligning AI initiatives with business
                    goals, teaching participants to assess AI readiness and
                    build an AI business strategy. <br />
                    <br />
                  </span>{" "}
                  <p className="text-[#666666] leading-tight">
                    Learn how to align AI initiatives with strategic objectives, assess organizational AI readiness, and foster an innovation-driven culture. Through AI training and interactive executive AI workshops, explore AI tools and gain hands-on experience and leave with a concrete framework for scaling AI within your organization.
                  </p>{" "}
                </span>
              </p>
            </div>

            <div className="flex justify-center w-full">
              <button
                className="lg:mt-20 mt-2 mb-12 pt-5 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>
        </article>
      </section>

      <section className="block md:hidden relative h-full flex flex-col justify-start bg-[#E6EBF8]">
        <header className="pt-8 text-left ml-4  ">
          <h1 className="text-2xl lg:text-5xl font-semibold font-display">
            Choose Your Program
          </h1>
        </header>

        <article className="flex flex-row overflow-x-auto px-4 mt-0  space-x-4 pb-20">
          <article className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img
              src={workk10}
              alt="AI Awareness Workshop"
              className="mt-[-10px]"
            />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-5 pb-5">
                <span className="lg:font-display text-xs md:text-base">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">3 hours</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">General AI awareness</span>
                  <br />
                </span>
                <p className="lg:font-display text-xs md:text-[15.55px] text-[#666666] leading-tight">
                  In just three hours, our AI awareness workshop provides your
                  team with a comprehensive introduction to AI, creating a solid
                  foundation for further exploration. Perfect for busy
                  professionals and business executives seeking an impactful,
                  quick dive into AI training concepts, this AI workshop is
                  designed to support AI upskilling and future AI-driven
                  initiatives within your organization.
                </p>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button
                className="lg:mt-16 mt-4 mb-10 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>

          <article className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img
              src={workk11}
              alt="AI Fundamentals Workshop"
              className="mt-[-10px]"
            />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-3 pb-5">
                <span className="lg:font-display md:text-base text-xs">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">1 day or 2 half days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Build a solid foundational understanding of AI’s concepts
                    and applications.
                  </span>
                  <br />
                </span>
                <p className="lg:font-display text-xs text-[#666666] md:text-[15.55px] leading-tight">
                  Gain a foundational understanding of AI concepts and
                  applications with our AI Fundamentals workshop. Covering the
                  basics of AI for business leaders, this session explores AI’s
                  transformative potential, real-world applications, hands-on
                  experiences, ethical considerations, and organizational
                  opportunities.
                  <br/>
                  <br />
                  Through this workshop, participants gain insight into AI’s
                  impact on their roles, fostering a culture of continuous
                  learning, innovation, and cross-functional collaboration. By
                  empowering staff with AI knowledge, the workshop lays a solid
                  foundation for future AI-driven initiatives, contributing to
                  organizational growth and adaptation in the rapidly evolving
                  AI landscape.
                  <br />
                </p>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button
                className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>

          <article className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img
              src={workk12}
              alt="Applied AI Workshop for Business Leaders"
              className="mt-[-10px]"
            />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-3 pb-5">
                <span className="lg:font-display md:text-base text-xs">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">2 days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Strategic application of AI in business
                  </span>
                  <br />
                </span>
                <span className="lg:font-display md:text-[15.55px] text-xs ">
                  <span className="font-bold text-[#666666]">
                    Day 1 focuses on the strategic application of AI in
                    business, equipping C-level executives with a fundamental
                    understanding of AI and machine learning basics.
                    <br />
                    <br />{" "}
                  </span>
                  <p className="text-[#666666] leading-tight">
                    This corporate AI training workshop allows participants
                    discover how AI optimizes decision-making, enhances user
                    experiences, and boosts operational efficiency. The session
                    also covers data quality as a crucial factor in AI success
                    and explores ethical considerations for responsible AI
                    adoption.
                  </p>{" "}
                  <br />
                  <br />
                  <p className="font-bold text-[#666666]">
                     Day 2 shifts focus to aligning AI initiatives with business
                    goals, teaching participants to assess AI readiness and
                    build an AI business strategy. <br />
                    <br />
                  </p>{" "}
                  <p className="text-[#666666] leading-tight">
                    Learn how to align AI initiatives with strategic objectives, assess organizational AI readiness, and foster an innovation-driven culture. Through AI training and interactive executive AI workshops, explore AI tools and gain hands-on experience and leave with a concrete framework for scaling AI within your organization.
                  </p>{" "}
                </span>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button
                className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Enroll Now
              </button>
            </div>
          </article>
        </article>
      </section>



      <section className="bg-white h-full lg:h-auto">
        <article className="justify-center items-center flex flex-col md:flex-row lg:pr-12 lg:pl-0 mt-16 2xl:mb-10">
          <img
            src={workk13}
            alt="AI Mastery for Healthcare"
            className="w-96 h-5/6 2xl:w-3/12 lg:ml-32 2xl:ml-72 2xl:pt-10 lg:pt-16"
          />

          <div className="container 2xl:mr-56 lg:mb-16 mt-6 lg:mt-12 px-4 lg:px-0">
            <form onSubmit={handleSubmitbrochure} className="max-w-xl mx-auto">
              <p className="text-3xl lg:text-5xl lg:pb-5 font-display text-[#C62B6D] font-bold">
                Download Brochure
              </p>

              <div className="mb-6 mt-6">
                <label className="block font-display font-medium text-[#666666] text-base mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  name="fullnamebrochure"
                  value={fullnamebrochure}
                  onChange={(e) => setfullnamebrochure(e.target.value)}
                  className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
                  placeholder="Enter your name"
                  required
                />
                {errorsbrochure.fullnamebrochure && (
                  <p className="text-red-500 text-sm">
                    {errorsbrochure.fullnamebrochure}
                  </p>
                )}
              </div>

              <div className="flex -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block font-display font-medium text-[#666666] text-base mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="emailbrochure"
                    value={emailbrochure}
                    onChange={(e) => setemailbrochure(e.target.value)}
                    className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
                    placeholder="Email"
                    required
                  />
                  {errorsbrochure.emailbrochure && (
                    <p className="text-red-500 text-sm">
                      {errorsbrochure.emailbrochure}
                    </p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label className="block font-display font-medium text-[#666666] text-base mb-2">
                    Job Title
                  </label>
                  <input
                    type="text"
                    name="jobtitlebrochure"
                    value={jobtitlebrochure}
                    onChange={(e) => setjobtitlebrochure(e.target.value)}
                    className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
                    placeholder="Job Title"
                    required
                  />
                  {errorsbrochure.jobtitlebrochure && (
                    <p className="text-red-500 text-sm">
                      {errorsbrochure.jobtitlebrochure}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block font-display font-medium text-[#666666] text-base mb-2">
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="companynamebrochure"
                    value={companynamebrochure}
                    onChange={(e) => setcompanynamebrochure(e.target.value)}
                    className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
                    placeholder="Company Name"
                    required
                  />
                  {errorsbrochure.companynamebrochure && (
                    <p className="text-red-500 text-sm">
                      {errorsbrochure.companynamebrochure}
                    </p>
                  )}
                </div>

                <div className="w-full md:w-1/2 px-3">
                  <label className="block font-display font-medium text-[#666666] text-base mb-2">
                    Country
                  </label>
                  <input
                    type="text"
                    name="countrybrochure"
                    value={countrybrochure}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^[A-Za-z]*$/.test(value)) {
                        setcountrybrochure(value);
                      }
                    }}
                    className="rounded w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
                    placeholder="Country"
                    required
                  />
                  {errorsbrochure.countrybrochure && (
                    <p className="text-red-500 text-sm">
                      {errorsbrochure.countrybrochure}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between lg:mt-12 mb-12 lg:mb-0">
                <button
                  type="submit"
                  className="bg-[#3E59B7] hover:bg-white hover:border-2 hover:text-[#666666] rounded-lg text-white w-44 font-bold py-3 px-4 font-display focus:outline-none focus:shadow-outline"
                >
                  Download
                </button>
              </div>
            </form>
          </div>
        </article>
      </section>

      <section className="flex justify-center relative bg-[#FCF6F9]">
        <article className="w-full max-w-screen-lg 2xl:max-w-screen-xl md:p-10 2xl:px-10  p-4 pt-7 relative">
          <p className="font-semibold text-[#666666] font-display md:text-2xl lg:text-4xl relative z-10">
            A Power House Team Of Renowned Industry Experts With Exceptional
            Real-World Experience.
          </p>

          <figure className="flex  flex-row relative z-10">
            <img
              src={workk14}
              alt="Randomwalk"
              className="lg:mt-10 mt-12 md:w-full lg:w-full w-40   md:max-w-xs  object-contain   lg:mr-5 "
            />
            <img
              src={line}
              alt="Line"
              className="md:h-20 h-10    mt-12  lg:ml-0  md:mt-10"
            />
            <img
              src={workk15}
              alt="Academy_Xi_img1"
              className="lg:mt-10 lg:w-60 md:w-64 lg:h-auto object-contain w-32  mt-12"
            />
          </figure>

          <p className=" pt-5 relative z-10">
            <span className=" md:text-base 2xl:text-lg text-[#666666] font-display leading-tight text-sm">
              Academy Xi- Random Walk’s official training delivery partner.
              Headquartered in Sydney, Australia, Academy Xi boasts an elite
              team of industry experts renowned for their profound domain
              knowledge and practical insights.
              <br />
              <br />
            </span>
          </p>

          <p className=" relative z-10">
            <span className="text-[#666666] 2xl:text-lg lg:text-base font-display text-sm">
              Together, we bridge the gap between theory and real-world
              application, ensuring our AI workshops deliver actionable insights
              that drive business impact.
            </span>
          </p>

          <div className="flex pt-10 flex-col md:flex-row  relative z-10">
            <div className="flex items-center">
              <img
                src={workk16}
                alt="Academy_Xi_img2"
                className="mt-4 w-10 md:w-16 lg:h-auto"
              />
              <p className="ml-4 pr-10  text-sm md:text-base 2xl:text-lg text-[#666666] font-display">
                Academy Xi is a proud member of the Tech Council of Australia.
              </p>
            </div>

            <div className="flex items-center  lg:ml-0 lg:mt-0">
              <img
                src={workk17}
                alt="Academy_Xi_img3"
                className="mt-4 w-10 md:w-16 lg:h-auto"
              />
              <p className="ml-2  text-sm md:text-base  2xl:text-lg text-[#666666] font-display mt-5">
                Academy Xi is a network partner of Australian Cyber Security
                Center.
              </p>
            </div>
          </div>
        </article>

        <img
          src={topright}
          alt="Academy_Xi_img4"
          className="absolute top-0 right-0 w-44 h-auto mt-5  z-0"
        />
        <img
          src={blue}
          alt="Academy_Xi_img5"
          className="absolute  top-0 right-0 w-44 h-auto mt-5  z-0"
        />
        <img
          src={bottomright}
          alt="ToAcademy_Xi_img6"
          className="absolute bottom-0  right-0 w-44 h-auto  z-0"
        />
      </section>

      <section className="flex justify-center relative mt-10">
        <article className="w-full max-w-screen-lg mx-4 lg:mx-0">
          <div className="w-full lg:w-auto">
            <h1 className="font-bold text-[#666666] font-display md:text-4xl lg:ml-32 mb-5 text-left">
              Meet Your Instructors
            </h1>
            <img
              src={pink}
              alt="Meet_your_instructor_img1"
              className="absolute top-0 right-0 w-1/3 h-auto z-0"
            />

            <div className="flex flex-col lg:flex-col">
              <div className="flex lg:flex-row md:items-center md:justify-center">
                <img
                  src={workk18}
                  alt="Amir Ansari"
                  className="mt-4 md:ml-12 md:mt-0  md:w-auto md:h-auto hidden md:block"
                />
                <img
                  src={workk18}
                  alt="Amir Ansari"
                  className=" w-32  h-full md:hidden"
                />

                <div className="bg-white w-auto h-auto md:p-5 md:py-20  p-2 shadow-2xl z-10 md:px-4 lg:px-10  2xl:px-20 mt-10 md:mt-0">
                  <p className="text-sm font-display  text-[#666666] ">
                    <span className="font-bold text-[#666666]  justify-center text-center lg:text-lg 2xl:text-xl">
                      Amir Ansari
                    </span>
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666]">
                      CXO and Head of Applied AI at Academy Xi, Australia
                    </span>
                    <br />
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] hidden md:block">
                      With over 25+ years of industry <br />
                      experience, he’s a seasoned strategist
                      <br /> in AI, serving as a university advisor,
                      <br /> international keynote speaker, and <br />
                      mentor for corporates and founders.
                      <br />
                      Former Global Head of Product Design <br />& user
                      experience, he specializes in <br />
                      crafting growth plans for organizations <br />
                      worldwide.
                    </span>
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] block md:hidden">
                      With over 25+ years of industry experience, he’s a
                      seasoned strategist in AI, serving as a university
                      advisor, international keynote speaker, and mentor for
                      corporates and founders. Former Global Head of Product
                      Design & user experience, he specializes in crafting
                      growth plans for organizations worldwide.
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row md:flex-row md:items-center  justify-center mt-4 lg:mt-8 mb-20 md:mb-0 ">
                <img
                  src={pinkblue}
                  alt="Meet_your_instructor_img2"
                  className="absolute left-0 bottom-0 w-1/3 h-auto z-0"
                />

                <div className="bg-white  lg:w-auto h-auto md:p-5 md:py-20 shadow-2xl z-10 md:px-4 lg:px-12 p-2  2xl:px-20  md:mt-0 mt-10">
                  <p className="text-sm font-display text-[#666666]">
                    <span className="font-bold md:text-lg text-[#666666] :text-xl">
                      Steven HK Ma
                    </span>
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666]">
                      Executive Business Mentor
                    </span>
                    <br />
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] hidden md:block">
                      Steven H.K. brings extensive industry <br />
                      experience, having led as CEO in the <br />
                      Fast 50 Rising star and served as
                      <br /> Director of Agility in sectors including
                      <br /> finance, transportation, aviation, and <br />
                      defense. Executive business mentor with deep
                      <br /> expertise in AI.
                    </span>
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] block md:hidden">
                      Steven H.K. brings extensive industry experience, having
                      led as CEO in the Fast 50 Rising star and served as
                      Director of Agility in sectors including finance,
                      transportation, aviation, and defense. Executive business
                      mentor with deep expertise in AI.
                    </span>
                  </p>
                </div>
                <img
                  src={workk19}
                  alt="Steven HK Ma"
                  className="w-32 h-full  md:hidden"
                />
                <img
                  src={workk19}
                  alt="Steven HK Ma"
                  className="mt-4 lg:mt-0 mb-10 lg:w-auto lg:h-auto hidden md:block z-10"
                />
              </div>
            </div>
          </div>
        </article>
      </section>

      <section className=" bg-no-repeat  bg-cover lg:px-24 md:px-10 rounded-2xl 2xl:w-auto 2xl:mx-40 ">
        <article className="container mx-auto p-4">
          <h1 className="text-center  md:text-2xl lg:text-2xl font-grayy lg:my-2 lg:pb-3 font-bold font-display  text-[#C62B6D]  2xl:text-3xl ">
            Frequently Asked Questions
          </h1>
          <DropdownList />
        </article>
      </section>

      <section
     id="consultation-section-tablet"

        className="hidden md:block lg:hidden relative h-[500px] flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <article className="absolute inset-0 bg-[#3E59B7] opacity-30"></article>

        <img
          src={workk21}
          alt="Schedule Consultation"
          className="absolute top-0 right-0 lg:w-3/5 w-10/12 h-[500px]"
        />

        <div className="absolute right-0 top-24 transform -translate-y-1/2 z-20  p-8 lg:w-1/2">
          <p className="text-gray-700 mb-4 md:ml-8 mt-3 lg:pt-10 text-left pl-56 md:text-left ">
            <span className="font-display text-white font-bold max-w-xs lg:text-xl  ">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display md:text-sm  text-xs text-white">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today.
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white">
              Schedule a free consultation with us to discuss your unique needs
              and discover how our Executive AI Workshops can empower your
              organization to drive growth.
            </span>
          </p>

          <form
            onSubmit={handleSubmitconsultation}
            className="absolute lg:right-0 md:right-10 mx-auto mt-0 lg:mt-10"
          >
            <div className="mb-0 ">
              <label className="block text-white font-display font-semibold text-sm mb-2 ">
                Full Name
              </label>
              <input
                type="text"
                id="FullName"
                required
                name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errorsconsultation.fullnameconsultation && (
                <p className="text-red-500 text-sm">
                  {errorsconsultation.fullnameconsultation}
                </p>
              )}
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  htmlFor="email"
                  className="block text-white  font-display font-semibold text-sm mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="mailconsultation"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorsconsultation.emailconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.emailconsultation}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="number"
                  className="block text-white font-display font-semibold text-sm mb-2"
                >
                  Phone Number
                </label>
                <PhoneInput
                  country={"in"}
                  id="phonenumberconsultation"
                  name="phonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(number, country) =>
                    handlePhoneNumberChangeconsultation(number, country)
                  }
                  inputClass="custom-phone-input2"
                  buttonClass="border rounded"
                  dropdownClass="border rounded"
                  required
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />
                {errorsconsultation.phonenumberconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.phonenumberconsultation}
                  </p>
                )}
              </div>
            </div>
            <div className="disclaimerContainerworkshop">
              <label className="checkboxLabel">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="checkboxs"
                />
                <span className="max-w-md text-white text-xs">
                  I agree and authorize team to contact me and/or send relevant
                  information over Email, SMS & WhatsApp. This will override the
                  registry with DNC/NDNC.
                </span>
              </label>
            </div>
            {errors.terms && (
              <p className="text-red-500 text-sm">{errors.terms}</p>
            )}
            <div className="linksContainers">
              <a href="/terms-of-service/" className="links">
                Terms of Service
              </a>
              <a href="/privacy-policy/" className="links">
                Privacy Policy
              </a>
            </div>
            <div className="flex items-center justify-left">
              <button
                type="submit"
                id="scheduleconsultation"
                className={`bg-pink-600 mb-10  lg:text-md hover:bg-blue-700 rounded-md  text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline${
                  !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isChecked || isLoading}
              >
                Schedule Consultation
              </button>
            </div>
          </form>
        </div>

        {isSuccessOpenconsultation && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModalconsultation}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModalconsultation}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>

      <section
   id="consultation-section-desktop"
        className="hidden md:hidden lg:block relative  h-screen flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <article className="absolute inset-0 bg-[#3E59B7] opacity-30"></article>

        <img
          src={workk21}
          alt="Schedule Consultation"
          className="absolute top-0 right-0 w-3/5 h-screen"
        />

        <div className="absolute right-0 top-24 2xl:-translate-y-0  2xl:top-16 transform -translate-y-1/2 z-20 p-8 lg:w-1/2">
          <p className="text-gray-700 mb-4 ml-3 mt-3 lg:pt-0">
            <span className="font-display text-white font-bold text-xl lg:text-xl 2xl:text-2xl ">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display text-sm text-white 2xl:text-lg  ">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today. Schedule a free consultation with us to discuss
              your unique needs and discover how our Executive AI Workshops can
              empower your organization to drive growth.
            </span>
          </p>

          <form
            onSubmit={handleSubmitconsultation}
            className="absolute   mx-auto mt-2 ml-4"
          >
            <div className="mb-4 ">
              <label
                htmlFor="name"
                className="block text-white font-display font-semibold text-sm mb-2 2xl:text-lg "
              >
                Full Name
              </label>
              <input
                type="text"
                id="FullName"
                required
                name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded w-full lg:py-2 lg:px-3 2xl:py-4 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errorsconsultation.fullnameconsultation && (
                <p className="text-red-500 text-sm">
                  {errorsconsultation.fullnameconsultation}
                </p>
              )}
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  htmlFor="email"
                  className="block text-white  font-display font-semibold text-sm 2xl:text-lg mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="emailconsultation"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full lg:py-2 lg:px-3 2xl:py-2 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorsconsultation.emailconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.emailconsultation}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2  px-3 ">
                <label
                  htmlFor="number"
                  className="block text-white font-display font-semibold 2xl:text-lg text-sm mb-2"
                >
                  Phone Number
                </label>
                <PhoneInput
                  country={"in"}
                  id="phonenumberconsultation"
                  name="phonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(number, country) =>
                    handlePhoneNumberChangeconsultation(number, country)
                  }
                  inputClass="custom-phone-input2 "
                  buttonClass="border rounded "
                  dropdownClass="border rounded"
                  required
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />
                {errorsconsultation.phonenumberconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.phonenumberconsultation}
                  </p>
                )}
              </div>
            </div>
            <div className="disclaimerContainerworkshop">
              <label className="checkboxLabel">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="checkboxs"
                />
                <span className="max-w-md text-white 2xl:text-base ">
                  I agree and authorize team to contact me and/or send relevant
                  information over Email, SMS & WhatsApp. This will override the
                  registry with DNC/NDNC.
                </span>
              </label>
            </div>
            {errors.terms && (
              <p className="text-red-500 text-sm">{errors.terms}</p>
            )}
            <div className="linksContainers lg:ml-20 2xl:ml-0 ">
              <a href="/terms-of-service/" className="links 2xl:ml-10 2xl:text-base">
                Terms of Service
              </a>
              <a href="/privacy-policy/" className="links 2xl:text-base">
                Privacy Policy
              </a>
            </div>
            <div className="flex items-center 2xl:ml-5 justify-left ml-10 2xl:mt-4 ">
              <button
                type="submit"
                id="scheduleconsultation"
                className={`bg-pink-600 mb-10 lg:pr-20 lg:pl-20 2xl:pr-24 2xl:pl-24 lg:text-md 2xl:text-lg hover:bg-blue-700 rounded-md  text-white font-bold py-4 px-4 focus:outline-none focus:shadow-outline ${
                  !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isChecked || isLoading}
              >
                {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
    "Schedule Consultation"
    )}
              </button>
            </div>
          </form>
        </div>

        {isSuccessOpenconsultation && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModalconsultation}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModalconsultation}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>

      <section
    id="consultation-section-mobile"
        className="block md:hidden relative  h-[600px] flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <article className="absolute inset-0 bg-[#3E59B7] opacity-30"></article>

        <img
          src={workk21}
          alt="Schedule Consultation"
          className="absolute top-0 right-0  w-[400px] h-[600px]"
        />

        <div className="absolute right-0 top-64  transform -translate-y-1/2 z-20 p-8 lg:w-2/5 text-left mt-24 pb-2">
          <p className="text-gray-700 mb-4 pl-20">
            <span className="font-display text-white font-bold lg:text-xl text-sm  text-left block">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white block">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today.
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white block">
              Schedule a free consultation with us to discuss your unique needs
              and discover how our Executive AI Workshops can empower your
              organization to drive growth.
            </span>
          </p>

          <form onSubmit={handleSubmitconsultation} className=" ">
            <div className=" pl-20">
              <label
                htmlFor="name"
                className="block text-white font-display font-light text-sm mb-2"
              >
                Full Name
              </label>
              <input
                type="text"
                id="FullName"
                required
                name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded  py-1 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              {errorsconsultation.fullnameconsultation && (
                <p className="text-red-500 text-sm">
                  {errorsconsultation.fullnameconsultation}
                </p>
              )}
            </div>

            <div className="flex flex-col mb-6 pl-16">
              <div className="w-full md:w-1/2 px-3  md:mb-0 ">
                <label
                  htmlFor="email"
                  className="block text-white font-display font-light text-sm mb-0"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="emailconsultation"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-1 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorsconsultation.emailconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.emailconsultation}
                  </p>
                )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="number"
                  className="block text-white font-display font-light text-sm mb-0"
                >
                  Number
                </label>
                <PhoneInput
                  country={"in"}
                  id="phonenumberconsultation"
                  name="phonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(number, country) =>
                    handlePhoneNumberChangeconsultation(number, country)
                  }
                  inputClass="custom-phone-input2"
                  buttonClass="border rounded"
                  dropdownClass="border rounded"
                  required
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />
                {errorsconsultation.phonenumberconsultation && (
                  <p className="text-red-500 text-sm">
                    {errorsconsultation.phonenumberconsultation}
                  </p>
                )}
              </div>
            </div>
            <div className="disclaimerContainerworkshop -translate-x-3 ml-10 ">
              <label className="checkboxLabels max-w-sm ">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className="checkboxs w-8"
                />
                <span className=" text-white text-xs ">
                  I agree and authorize team to contact me and/or send relevant
                  information over Email, SMS & WhatsApp. This will override the
                  registry with DNC/NDNC.
                </span>
              </label>
            </div>
            {errors.terms && (
              <p className="text-red-500 text-sm ">{errors.terms}</p>
            )}
            <div className="linksContainers-mobile">
              <a href="/terms-of-service/" className="links-mobile ml-10">
                Terms of Service
              </a>
              <a href="/privacy-policy/" className="links-mobile ml-4">
                Privacy Policy
              </a>
            </div>
            <div className="flex items-center justify-center mt-4 mb-44 ml-12 ">
              <button
                type="submit"
                id="scheduleconsultation"
                className={`bg-pink-600 text-sm hover:bg-blue-700 rounded-md text-white font-bold py-2 px-2 focus:outline-none focus:shadow-outline ${
                  !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={!isChecked || isLoading}
              >
              {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
    "Schedule Consultation"
    )}
          
              </button>
            </div>
          </form>
        </div>
        {isSuccessOpenconsultation && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModalconsultation}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModalconsultation}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>

      <div>
        {isModalOpen && (
          <div className="fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-[#F1F6FF]   p-8 lg:px-10 rounded-lg max-h-screen   max-w-md w-full">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>

              <form onSubmit={handleSubmitcontact}>
                <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                  <div className="mb-4">
                    <label className=" text-gray-700">First Name</label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.firstname && (
                      <p className="text-red-500 text-sm">{errors.firstname}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname5"
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.lastname && (
                      <p className="text-red-500 text-sm">{errors.lastname}</p>
                    )}
                  </div>

                  <div className="mb-4 w-full">
                    <label
                      htmlFor="phoneNumber"
                      className="block text-gray-700 text-sm mb-2"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      type="tel"
                      id="Phonenumber"
                      required
                      name="phonenumber"
                      value={phoneNumber}
                      onChange={(number, country) =>
                        handlePhoneNumberChange(number, country)
                      }
                      inputClass="custom-phone-input2"
                      buttonClass="border rounded"
                      dropdownClass="border rounded"
                      placeholder="Enter Phone Number"
                      enableSearch={true}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-700">Company Name</label>
                    <input
                      type="text"
                      id="companyname"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700">Business Email</label>
                    <input
                      type="email"
                      id="businessemail"
                      name="businessemail"
                      value={businessemail}
                      onChange={(e) => setEmailId(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.businessemail && (
                      <p className="text-red-500 text-sm">
                        {errors.businessemail}
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className=" text-gray-700">
                      Project Details (Optional)
                    </label>
                    <input
                      type="text"
                      id="projectdetails"
                      name="projectdetails"
                      value={projectdetails}
                      onChange={(e) => setprojectdetails(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  <div className="disclaimerContainercontact">
                    <label className="checkboxLabel">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="checkbox"
                      />
                      <span className="checkboxText">
                        I agree and authorize team to contact me and/or send
                        relevant information over Email, SMS & WhatsApp. This
                        will override the registry with DNC/NDNC.
                      </span>
                    </label>
                  </div>
                  {errors.terms && (
                    <p className="text-red-500 text-sm">{errors.terms}</p>
                  )}
                  <div className="linksContainer">
                    <a href="/terms-of-service/" className="link">
                      Terms of Service
                    </a>
                    <a href="/privacy-policy/" className="link">
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div className="mb-4">
                <button
    id="submit"
    type="submit"
    className={`w-full px-4 py-2 bg-[#C62B6D] text-white rounded ${
      !isChecked || isLoading ? "opacity-50 cursor-not-allowed" : ""
    }`}
    disabled={!isChecked || isLoading}
  >
    {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
      "Submit"
    )}
  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {isSuccessOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                id="close"
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Workshopduplicate;
