import React from "react";
import "./About.css";
import Popup from "../components/popup.js";
import { Client, Databases, ID } from "appwrite";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Randomwalklogo from "../assets/Visual-ai/Randomwalklogo.webp";
import Randomwalkbg from "../assets/Visual-ai/RandomWalk.webp";
import Randomwalkvisbg from "../assets/Visual-ai/Randomwalkbg.webp";
import bubble from "../assets/Visual-ai/bubble.webp";
import random from "../assets/Visual-ai/random.gif";
import cap from "../assets/Visual-ai/cap.webp";
import videofile from "../assets/Visual-ai/video.mp4";
import retail from "../assets/Visual-ai/retail.webp";
import manufacturing from "../assets/Visual-ai/manufacturing.webp";
import construction from "../assets/Visual-ai/construction.webp";
import healthcare from "../assets/Visual-ai/healthcare.webp";
import notification from "../assets/Visual-ai/notification.webp";
import loop from "../assets/Visual-ai/loop.webp";
import time from "../assets/Visual-ai/time.webp";
import calender from "../assets/Visual-ai/calender.webp";
import scalable from "../assets/Visual-ai/scalable.webp";
import adopt from "../assets/Visual-ai/adopt.webp";
import track from "../assets/Visual-ai/track.webp";
import advance from "../assets/Visual-ai/advance.webp";
import visbackground from "../assets/Visual-ai/visbackground.webp";
import circle from "../assets/Visual-ai/circle.webp";
import hand from "../assets/Visual-ai/hand.webp";
import logocolor from "../assets/Visual-ai/logocolor.webp";
import visualai from "../assets/Visual-ai/visualai.webp";
import video from "../assets/Visual-ai/visualaivideo.mp4"
import visualai6 from "../assets/Visual-ai/visualai6.webp";
import visualai7 from "../assets/Visual-ai/visualai7.webp";
import visualai8 from "../assets/Visual-ai/visualai8.webp";

import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

import PhoneInput from "react-phone-input-2";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const Visualai = ({ onContactClick }) => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 4;
  const totalSlideskey = 5;
  const autoScrollInterval = 5000; // 5 seconds per slide
  const carouselRef = useRef(null);
  const carouselRefkey = useRef(null);

  // Using the directly imported images
  const images = [retail, manufacturing, construction, healthcare];

  // Content for each slide
  const slides = [
    {
      title: "Retail & Commercial Spaces",
      points: [
        {
          label: "Footfall Analytics",
          text: "Track visitor movement patterns to optimize store layouts and enhance customer experience.",
        },
        {
          label: "Queue Management & Waiting Time Analysis",
          text: "Monitor customer wait times at service desks and checkout counters to improve efficiency.",
        },
        {
          label: "Anomaly Detection",
          text: "Identify suspicious behavior, theft, or unusual crowd formations for security teams.",
        },
      ],
    },
    {
      title: "Manufacturing & Industrial Sites",
      points: [
        {
          label: "Safe Distance Monitoring",
          text: "Ensure a safe working environment by maintaining human-machine distance regulations.",
        },
        {
          label: "Fall Detection & Hazard Alerts",
          text: "Detect slips, falls, and worker distress in real time to initiate immediate response.",
        },
        {
          label: "Fire & Smoke Detection",
          text: "AI-powered detection systems can alert operators before fires escalate.",
        },
        {
          label: "SOP Compliance & Board Detection",
          text: "Ensure adherence to safety standard operating procedures through AI-driven monitoring.",
        },
      ],
    },
    {
      title: "Construction & Infrastructure",
      points: [
        {
          label: "Structural Stability & Defect Identification",
          text: "Analyze cracks, alignment issues, and material degradation in buildings.",
        },
        {
          label: "Workplace Safety Violations",
          text: "Detect missing PPE, unsafe scaffolding, and unauthorized personnel access.",
        },
        {
          label: "Placement of Safety Provisions",
          text: "Verify the correct positioning of safety blinds, barriers, and emergency exits.",
        },
      ],
    },
    {
      title: "Healthcare & Public Services",
      points: [
        {
          label: "PPE & Hygiene Compliance",
          text: "Ensure adherence to protective gear and sanitation protocols in hospitals and laboratories.",
        },
        {
          label: "Emergency Response Readiness",
          text: "Identify medical emergencies in real time and trigger immediate response measures.",
        },
      ],
    },
  ];

  useEffect(() => {
    let interval;
    
    // Only set up interval if the ref exists and is stable
    if (carouselRef.current) {
      interval = setInterval(() => {
        const nextSlide = (currentSlide + 1) % totalSlides;
        setCurrentSlide(nextSlide);
  
        // Scroll the carousel
        const scrollPosition = nextSlide * carouselRef.current.offsetWidth;
        carouselRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }, autoScrollInterval);
    }
  
    // Clean up the interval on unmount or when dependencies change
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentSlide, totalSlides, autoScrollInterval]);


  const handleScroll = () => {
    if (carouselRef.current) {
      const scrollPosition = carouselRef.current.scrollLeft;
      const slideWidth = carouselRef.current.offsetWidth;
      const newSlide = Math.round(scrollPosition / slideWidth);
  
      if (
        newSlide !== currentSlide &&
        newSlide >= 0 &&
        newSlide < totalSlides
      ) {
        setCurrentSlide(newSlide);
      }
    }
  };

  useEffect(() => {
    let interval;
    
    // Only set up interval if the ref exists and is stable
    if (carouselRefkey.current) {
      interval = setInterval(() => {
        const nextSlide = (currentSlide + 1) % totalSlideskey;
        setCurrentSlide(nextSlide);
  
        // Scroll the carousel
        const scrollPosition = nextSlide * carouselRefkey.current.offsetWidth;
        carouselRefkey.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }, autoScrollInterval);
    }
  
    // Clean up the interval on unmount or when dependencies change
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [currentSlide, totalSlideskey, autoScrollInterval]);


  useEffect(() => {
    if (!window.gtmInitialized) {
      const gtmScript = document.createElement("script");
      gtmScript.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T7397TMR');
      `;
      document.head.appendChild(gtmScript);

      const gtmNoscript = document.createElement("noscript");
      const gtmIframe = document.createElement("iframe");
      gtmIframe.src =
        "https://www.googletagmanager.com/ns.html?id=GTM-T7397TMR";
      gtmIframe.height = "0";
      gtmIframe.width = "0";
      gtmIframe.style.display = "none";
      gtmIframe.style.visibility = "hidden";
      gtmNoscript.appendChild(gtmIframe);
      document.body.prepend(gtmNoscript);

      window.gtmInitialized = true;

      return () => {
        document.head.removeChild(gtmScript);
        document.body.removeChild(gtmNoscript);
      };
    }
  }, []);

  useEffect(() => {
    document.title = "Visual ai | Random Walk";

    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation."
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation.";
      document.head.appendChild(meta);
    }

    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute("content", "Visual ai | Random Walk");

    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute(
      "content",
      "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation."
    );

    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement("meta");
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute("content", "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement("meta");
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute("content", "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement("meta");
      ogImage.setAttribute("property", "og:image");
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute("content", `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement("meta");
      ogType.setAttribute("property", "og:type");
      document.head.appendChild(ogType);
    }
    ogType.setAttribute("content", "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement("link");
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/visual-ai";
  }, []);

  const validateEmail = (email) => {
    // Basic email format val
    // ation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }

    // Clean the email for case-insensitive comparison
    const lowerEmail = email.toLowerCase();
    const domain = lowerEmail.split("@")[1]; // Get the domain part after @

    // Block gmail domains
    if (domain.includes("gmail.")) {
      return "Please use a business email address instead of gmail";
    }

    // Block additional specified domains
    const blockedDomains = [
      "na.com",
      "example.com",
      "exmple.com", // Catching the misspelled version too
      "www.com",
      "chateleon.com",
      "pin.it",
      "notyetprocessed.com",
      "nowebsite.com",
    ];

    // Check if domain starts with any of the blocked domains
    // This handles cases like example.com/ as well
    for (const blockedDomain of blockedDomains) {
      if (domain.startsWith(blockedDomain)) {
        return "Please use a valid business email address";
      }
    }

    return ""; // Empty string means no error
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessemail, setEmailId] = useState("");
  const [projectdetails, setprojectdetails] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setfirstname("");
    setlastname("");
    setPhoneNumber("");

    setCompanyName("");
    setEmailId("");
    setprojectdetails("");
    setIsChecked(true);
    setErrors({});
  };

  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
    resetForm();
  };

  const handleSubmitcontact = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const emailError = validateEmail(businessemail);
      if (emailError) {
        setErrors({ ...errors, businessemail: emailError });
        setIsLoading(false);
        return;
      }
      if (!phoneNumber) {
        setErrors({ phoneNumber: "Phone number is required" });
        setIsLoading(false);
        return;
      }

      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        setIsLoading(false);
        return;
      }

      const formData = {
        firstname,
        lastname,
        businessemail,
        companyName,
        phoneNumber,
        projectdetails,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "visual-ai",
      };

      // First try to submit to Appwrite
      let appwriteSuccess = false;
      try {
        await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID2,
          ID.unique(),
          formData
        );
        appwriteSuccess = true;
      } catch (appwriteError) {
        console.error("Appwrite submission failed:", appwriteError);
        // Continue with backend submission even if Appwrite fails
      }

      // Then try to submit to Express backend
      let backendSuccess = false;
      try {
        // Fix the double slash by ensuring the URL is properly formatted
        const backendUrl = process.env.REACT_APP_BACKEND_URL.replace(
          /\/+$/,
          ""
        );
        const response = await fetch(`${backendUrl}/api/submit-form`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          // Attempt to parse response based on content type
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.includes("application/json")) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to submit form");
          } else {
            // Handle non-JSON responses
            const textError = await response.text();
            throw new Error(
              `Server error (${response.status}): ${textError.substring(
                0,
                100
              )}...`
            );
          }
        }

        // Try to parse response as JSON
        const responseData = await response.json();
        backendSuccess = true;
      } catch (backendError) {
        console.error("Backend submission failed:", backendError);
        // If backend fails but Appwrite succeeded, we can still consider it a partial success
      }

      // If either submission was successful, show success message
      if (appwriteSuccess || backendSuccess) {
        setIsModalOpen(false);
        navigate("/visual-ai/thankyou");
        resetForm();
      } else {
        // Both submissions failed
        throw new Error("Failed to submit form to both services");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors((prev) => ({
        ...prev,
        submit:
          "Failed to submit form. Please try again or contact support if the issue persists.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  console.log(`Backend URL: ${process.env.REACT_APP_BACKEND_URL}`);
  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {

      const fullNumber = number.startsWith("+") ? number : `+${number}`;

     
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
      
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  const benefits = [
    {
      icon: notification, 
      title: "Incident Prevention & Real-Time Alerts",
      description:
        "AI-powered systems instantly flag safety risks and operational bottlenecks.",
    },
    {
      icon: calender, 
      title: "Data-Driven Decision Making",
      description:
        "Gain actionable insights for predictive risk management and efficiency improvements.",
    },
    {
      icon: scalable, 
      title: "Customizable & Scalable",
      description:
        "Solutions tailored for diverse industries and evolving business needs.",
    },
    {
      icon: loop, 
      title: "Regulatory Compliance & Risk Mitigation",
      description:
        "Ensure adherence to industry regulations while avoiding penalties.",
    },
    {
      icon: time, 
      title: "Reduced Operational Downtime",
      description:
        "AI-driven monitoring improves workflows and prevents costly disruptions.",
    },
  ];




  return (
    <>
      <div className="">
        <section
          className="relative h-full md:h-screen w-full flex flex-col lg:flex-row justify-center items-center bg-no-repeat bg-cover bg-center px-6 lg:px-8"
          style={{ backgroundImage: `url(${Randomwalkbg})` }}
        >
          <div className="absolute top-4 right-4 lg:top-2 lg:right-12">
            <img
              src={Randomwalklogo}
              alt="RandomWalk Logo"
              className="w-24 md:w-64 lg:w-96 lg:h-28"
            />
          </div>

          <div className="w-full lg:w-1/2 text-center lg:text-left text-white relative z-10 px-4 lg:px-10 font-poppins mt-12 md:mt-16">
            <h1 className="text-3xl lg:text-[40px] 2xl:text-6xl font-extrabold text-white  lg:mt-[-80px] ">
              Revolutionizing{" "}
              <span className="text-yellow-400 leading-[1.6]">
                Workplace Safety
              </span>{" "}
              &
              <br /> Operational Efficiency <br />
              <span className="text-yellow-400 leading-[1.8]">
                with Visual AI
              </span>
            </h1>

            <p className="text-base lg:text-xl 2xl:text-2xl mt-4 font-bold leading-tight">
              Ensure a secure, compliant, and highly efficient environment with
              <span className="font-bold text-black">
                {" "}
                Random Walk’s advanced Visual AI solutions
              </span>
              . Our AI-powered systems go beyond safety, delivering real-time
              analytics, automated incident detection, and operational insights
              tailored to multiple industries.
            </p>

            <button
              className="mt-6 font-poppins px-6 py-3 text-3xl font-bold text-white bg-[#df0060] rounded-3xl shadow-md  border-4 border-[#d3689c]
      hover:bg-pink-600 transition-all duration-150"
              onClick={handleButtonClickcontact}
            >
              Got Questions?
            </button>
          </div>

          <div className="w-full lg:w-1/2 flex justify-center lg:justify-center mt-12 lg:mt-16 relative mb-4 md:mb-0">
  <video
    src={videofile} 
    autoPlay
    loop
    muted
    className="w-76  md:w-9/12 lg:w-11/12 object-contain"
  />
</div>

        </section>

      
        <section
          className="flex flex-col items-center text-center h-full lg:h-screen py-16 px-4 relative "
          style={{
            backgroundImage: `url(${Randomwalkvisbg})`,
            backgroundSize: "cover",
          }}
        >
          <h2 className="text-4xl md:text-5xl 2xl:text-6xl font-semibold text-[#c62b6d] drop-shadow-md">
            Smart Operations with{" "}
            <span className="text-[#e0004d] font-bold ">Visual AI</span>
          </h2>

          <p className="text-lg md:text-base 2xl:text-xl text-black font-semibold mt-10 max-w-5xl">
            AI systems can identify and report near-misses more effectively than
            manual methods, allowing for proactive interventions. Here’s how it
            impacted our users.
          </p>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12 lg:mt-8 px-16 2xl:px-44 2xl:mt-20 ">
            <div className="bg-white shadow-lg rounded-3xl p-6 border-4 border-[#a5b9ff]">
              <h3 className="text-6xl 2xl:text-7xl font-extrabold text-[#025da9]">
                20%
              </h3>
              <p className="mt-5 text-black 2xl:text-xl font-semibold mb-6">
                drop in speeding cases in the first week
              </p>
            </div>

            <div className="bg-white shadow-lg rounded-3xl p-6 border-4 border-[#a5b9ff]">
              <h3 className="text-6xl 2xl:text-7xl font-extrabold text-red-500">
                27%
              </h3>
              <p className="mt-5 text-black 2xl:text-xl font-semibold mb-6">
                decrease in lost day rate (LDR) within a year
              </p>
            </div>

            <div className="bg-white shadow-lg rounded-3xl p-6 border-4 border-[#a5b9ff]">
              <h3 className="text-6xl 2xl:text-7xl font-extrabold text-[#ff9900]">
                52x
              </h3>
              <p className="mt-5 text-black 2xl:text-xl font-semibold mb-6">
                more efficient than manual safety inspection
              </p>
            </div>

            <div className="bg-white shadow-lg rounded-3xl p-6 border-4 border-[#a5b9ff] mb-32 md:mb-0">
              <h3 className="text-6xl 2xl:text-7xl font-extrabold text-[#60bb46]">
                89%
              </h3>
              <p className="mt-5 text-black 2xl:text-xl font-semibold mb-6">
                reduction in safety risks related to the absence of hard hats
              </p>
            </div>
          </div>

          {/* Bubbles Image (Centered at Bottom) */}
          <div
            className="absolute bottom-0 left-1/2 -translate-x-1/2 z-10  w-72 2xl:w-3/12 cursor-pointer"
            onClick={handleButtonClickcontact}
          >
            <img
              src={bubble}
              alt="Bubbles"
              className="w-full h-full object-contain"
            />
          </div>
        </section>
        <section className="flex flex-col md:flex-row bg-red-100  max-w-full lg:h-screen items-center justify-between px-4 lg:px-8 py-6 ">
          <div className="w-full md:w-1/2 flex flex-col items-center mt-12">
            <img
              src={visualai}
              alt="Description"
              className="w-80 lg:w-11/12 h-auto 2xl:w-10/12 shadow-md rounded-3xl"
            />
          </div>
          <div className="w-full md:w-1/2 font-poppins flex flex-col items-center">
            <h2 className="text-center lg:text-right 2xl:text-center text-5xl font-extrabold text-[#c62b6d]  px-10 mt-4 lg:mt-0">
              Proven Expertise in
              <br className="block md:hidden" />
              <span className="text-[#c62b6d] mt-4 inline-block">
                Visual AI
              </span>
            </h2>

            <div className="px-10">
              <p
                className="text-lg 2xl:text-2xl text-black mt-4 text-center font-normal font-poppins cursor-pointer"
                onClick={handleButtonClickcontact}
              >
                <span className="font-bold">
                  Random Walk has successfully delivered 5+ large-scale Visual
                  AI projects, each monitoring
                </span>{" "}
                <span className="font-bold">
                  100+ key performance indicators (KPIs).
                </span>{" "}
                These projects cover vast spaces, processing multiple CCTV feeds
                simultaneously. Our proprietary AI models converge multiple
                video streams, cross-verifying events before flagging or
                recording incidents, significantly reducing false positives.
              </p>
              <p
                className="text-lg 2xl:text-2xl text-black mt-10 font-normal text-center font-poppins cursor-pointer"
                onClick={handleButtonClickcontact}
              >
                With <span className="font-bold">large-scale datasets</span>{" "}
                used for model training, we continuously enhance precision and
                reliability, ensuring cutting-edge AI performance for our
                clients.
              </p>
            </div>
          </div>
        </section>
        <section className="flex flex-col items-center font-poppins justify-center text-center min-h-full md:h-screen px-6 bg-white">
          <h2
            className="text-4xl md:text-5xl 2xl:text-7xl font-extrabold text-[#c62b6d] leading-[1.8] cursor-pointer"
            onClick={handleButtonClickcontact}
          >
            Expanding Beyond Safety: Tailored
            <br className="hidden md:block" />
            <span className="mt-4 inline-block">Visual AI Applications</span>
          </h2>

          {/* Subtitle */}
          <p
            className="text-lg md:text-xl 2xl:text-2xl text-gray-500 font-medium mt-6 cursor-pointer"
            onClick={handleButtonClickcontact}
          >
            While safety remains a core focus,
          </p>
          <p
            className="text-lg md:text-xl 2xl:text-3xl  text-[#545454] mt-4 font-poppins font-bold cursor-pointer"
            onClick={handleButtonClickcontact}
          >
            Random Walk’s Visual AI solutions extend to various industry needs
            ...
          </p>

          <button
            className="mt-6 font-poppins px-6 py-3 text-3xl 2xl:text-4xl font-bold text-white bg-[#df0060] rounded-3xl shadow-md  border-4 border-[#d3689c]
      hover:bg-pink-600 transition-all duration-150"
            onClick={handleButtonClickcontact}
          >
            Ask Our Specialists
          </button>
        </section>
        <section className="bg-[#EAF4FF]  hidden md:block  py-12 px-6 md:px-16 rounded-3xl  lg:w-[1000px] xl:w-[1200px] 2xl:w-[1400px] font-poppins shadow-lg">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-8">
            {/* Left Side: Text Content */}
            <div
              className="md:w-1/2 text-center md:text-left px-6 cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <h2 className="text-3xl md:text-3xl font-extrabold text-[#c62b6d] mb-6">
                Retail & Commercial Spaces
              </h2>
              <p className="text-gray-500 text-lg font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Footfall Analytics –
                </span>{" "}
                Track visitor movement patterns to optimize store layouts and
                enhance customer experience.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Queue Management & Waiting Time Analysis –
                </span>{" "}
                Monitor customer wait times at service desks and checkout
                counters to improve efficiency.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Anomaly Detection –
                </span>{" "}
                Identify suspicious behavior, theft, or unusual crowd formations
                for security teams.
              </p>
            </div>

            {/* Right Side: Image Content */}
            <div
              className="md:w-1/2 relative cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <img
                src={retail}
                alt="Retail and Commercial Analysis"
                className="rounded-3xl shadow-lg"
              />
            </div>
          </div>
        </section>

        
        <section className="bg-[#EAF4FF] hidden md:block lg:w-[1000px] xl:w-[1200px] 2xl:w-[1400px] ml-auto py-12 px-6 md:px-16 rounded-3xl mt-20 shadow-lg">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div
              className="md:w-1/2 relative cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <img
                src={manufacturing}
                alt="Manufacturing and Industrial Sites"
                className="rounded-3xl shadow-lg"
              />
            </div>

            {/* Right Side: Text Content */}
            <div
              className="md:w-1/2 text-center md:text-left font-poppins cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <h2 className="text-3xl md:text-4xl font-extrabold text-[#c62b6d] mb-6">
                Manufacturing & Industrial Sites
              </h2>

              <p className="text-gray-500 text-lg font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  Safe Distance Monitoring –
                </span>{" "}
                Ensure a safe working environment by maintaining human-machine
                distance regulations.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  Fall Detection & Hazard Alerts –
                </span>{" "}
                Detect slips, falls, and worker distress in real time to
                initiate immediate response.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  Fire & Smoke Detection –
                </span>{" "}
                AI-powered detection systems can alert operators before fires
                escalate.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  SOP Compliance & Board Detection –
                </span>{" "}
                Ensure adherence to safety standard operating procedures through
                AI-driven monitoring.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-[#EAF4FF] hidden md:block py-12 px-6 md:px-16 rounded-3xl lg:w-[1000px] xl:w-[1200px] 2xl:w-[1400px] font-poppins shadow-lg mt-20">
          <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-8">
            {/* Left Side: Text Content */}
            <div
              className="md:w-1/2 text-center md:text-left px-6 cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <h2 className="text-3xl md:text-3xl font-extrabold text-[#c62b6d] mb-6">
                Construction & Infrastructure
              </h2>
              <p className="text-gray-500 text-lg font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Structural Stability & Defect Identification –
                </span>
                Analyze cracks, alignment issues, and material degradation in
                buildings.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Workplace Safety Violations –
                </span>
                Detect missing PPE, unsafe scaffolding, and unauthorized
                personnel access.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold text-center">
                <span className="font-bold text-gray-700">
                  Placement of Safety Provisions –
                </span>
                Verify the correct positioning of safety blinds, barriers, and
                emergency exits.
              </p>
            </div>

            {/* Right Side: Image Content */}
            <div
              className="md:w-1/2 relative cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <img
                src={construction} // Replace with your actual image variable or URL
                alt="Construction and Infrastructure Analysis"
                className="rounded-3xl shadow-lg"
              />
            </div>
          </div>
        </section>

        <section className="bg-[#EAF4FF] hidden md:block lg:w-[1000px] xl:w-[1200px] 2xl:w-[1400px] ml-auto py-12 px-6 md:px-16 font-poppins rounded-3xl mt-20 shadow-lg">
          <div className="flex flex-col md:flex-row items-center gap-8">
            <div
              className="md:w-1/2 relative cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <img
                src={healthcare}
                alt="Healthcare & Public Services"
                className="rounded-3xl shadow-lg"
              />
            </div>

            {/* Right Side: Text Content */}
            <div
              className="md:w-1/2 text-center md:text-left font-poppins cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              <h2 className="text-3xl md:text-4xl font-extrabold text-[#c62b6d] mb-6">
                Healthcare & Public Services
              </h2>

              <p className="text-gray-500 text-lg font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  PPE & Hygiene Compliance –
                </span>{" "}
                Ensure adherence to protective gear and sanitation protocols in
                hospitals and laboratories.
              </p>
              <p className="text-gray-500 text-lg mt-4 font-semibold px-6 text-center">
                <span className="font-bold text-gray-700">
                  Emergency Response Readiness –
                </span>{" "}
                Identify medical emergencies in real time and trigger immediate
                response measures.
              </p>
            </div>
          </div>
        </section>

        <section className="block md:hidden w-full bg-blue-50 py-8 font-poppins mt-8">
      <div className="relative overflow-hidden">
        <div
          ref={carouselRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
          style={{ 
            scrollbarWidth: "none", 
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch" 
          }}
          onScroll={handleScroll}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full snap-center px-4"
            >
              <div className="bg-[#EAF4FF] rounded-xl shadow-lg overflow-hidden h-full flex flex-col">
                {/* Image */}
                <div className="w-full h-48">
                  <img
                    src={images[index]}
                    alt={slide.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                
                {/* Content */}
                <div className="p-6 flex-grow flex flex-col">
                  <h2 className="text-2xl font-extrabold text-[#c62b6d] mb-4">
                    {slide.title}
                  </h2>
                  
                  <div className="space-y-3 overflow-y-auto flex-grow">
                    {slide.points.map((point, idx) => (
                      <p key={idx} className="text-gray-600 text-sm">
                        <span className="font-bold text-gray-700">
                          {point.label} –{" "}
                        </span>
                        {point.text}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        
 
      </div>
    </section>
        <section className="bg-[#EAF4FF] h-full py-12 font-poppins px-6 md:px-16 lg:h-screen 2xl:h-full mt-10 shadow-lg">
          <div className="py-12 max-w-[1200px] mx-auto rounded-3xl bg-white">
            <h2 className="text-3xl md:text-5xl 2xl:text-6xl font-extrabold text-[#c62b6d] text-center mb-10">
              Hear from our users
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-6 mt-20 ">
              {/* User 1 */}
              <div className="bg-[#EAF4FF] p-6  shadow-lg text-center relative mt-5">
                <img
                  src={visualai6}
                  alt="Martha Blevins"
                  className="w-44 h-44 mx-auto rounded-2xl mb-4 -mt-20 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h3 className="text-lg font-bold text-gray-700">
                  Martha Blevins
                </h3>
                <p className="text-[#545454] mt-2 text-sm">
                  Our store's safety and efficiency have improved significantly
                  with AI-powered footfall analytics and queue management.
                  Customers are happier, and security concerns have reduced.
                </p>
              </div>

              {/* User 2 */}
              <div className="bg-[#EAF4FF] p-6  shadow-lg text-center relative mt-5">
                <img
                  src={visualai7}
                  alt="Alfonso Perez"
                  className="w-44 h-44 mx-auto rounded-2xl mb-4 -mt-20 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h3 className="text-lg font-bold text-gray-700">
                  Alfonso Perez
                </h3>
                <p className="text-[#545454] mt-2 text-sm">
                  The fall detection and fire alert systems have made our
                  workplace much safer. We've prevented multiple incidents and
                  feel more confident about worker safety.
                </p>
              </div>

              {/* User 3 */}
              <div className="bg-[#EAF4FF] p-6 shadow-lg text-center relative mt-5">
                <img
                  src={visualai8}
                  alt="Sylvia Davies"
                  className="w-44 h-44 mx-auto rounded-2xl mb-4 -mt-20 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h3 className="text-lg font-bold text-gray-700">
                  Sylvia Davies
                </h3>
                <p className="text-[#545454] mt-2 text-sm">
                  AI monitoring has helped us catch safety violations early,
                  ensuring our workers stay protected. It's made a real
                  difference in reducing risks on-site.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-[#EAF4FF] hidden md:block h-full  py-12  rounded-3xl font-poppins shadow-lg">
          <div className="grid md:grid-cols-3 gap-1 items-center">
            <div className="md:col-span-1 text-center md:text-left ">
              <h2 className="text-3xl md:text-5xl font-extrabold text-[#c62b6d] mb-6 px-6 md:px-16">
                <span className="block mb-2">Key Benefits of</span>
                <span className="block">Visual AI Solutions</span>
              </h2>

              <div
                className="flex flex-col justify-end md:items-start relative cursor-pointer"
                onClick={handleButtonClickcontact}
              >
                <img src={adopt} alt="Arrow and Circle" className="w-96" />
              </div>
            </div>

            <div
              className="md:col-span-2 space-y-2 px-10 cursor-pointer"
              onClick={handleButtonClickcontact}
            >
              {benefits.map((benefit, index) => (
                <div
                  key={index}
                  className="flex items-center bg-white shadow-lg rounded-3xl p-4 border-2 border-yellow-300 h-32"
                >
                  <div className="flex-shrink-0  lg:w-16 h-16 bg-white border-4 border-[#c62b6d] rounded-full flex items-center justify-center">
                    <img
                      src={benefit.icon}
                      alt={benefit.title}
                      className="w-10 h-10"
                    />
                  </div>

                  <div className="flex items-center space-x-4 w-full">
                    <h3 className="text-base md:text-2xl font-bold text-gray-700 w-1/2 text-right">
                      {benefit.title}
                    </h3>

                    <div className="h-12 w-1 bg-pink-300"></div>

                    <p className="text-gray-500 text-xs md:text-sm w-1/2">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-[#EAF4FF] block md:hidden h-full py-12 rounded-3xl font-poppins shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-1 items-center">
            <div className="md:col-span-1 text-center md:text-left mb-6 md:mb-0">
              <h2 className="text-2xl md:text-3xl lg:text-5xl font-extrabold text-[#c62b6d] mb-4 md:mb-6 px-4 md:px-8 lg:px-16">
                <span className="block mb-1 md:mb-2">Key Benefits of</span>
                <span className="block">Visual AI Solutions</span>
              </h2>
              <div
                className="flex justify-center md:justify-start md:items-start relative cursor-pointer"
                onClick={onContactClick}
              >
                <img
                  src={adopt}
                  alt="Arrow and Circle"
                  className="w-48 md:w-64 lg:w-96"
                />
              </div>
            </div>

            <div className="md:col-span-2 px-4 md:px-6 lg:px-10">
            
              <div
                ref={carouselRefkey}
                className="overflow-x-auto flex snap-x snap-mandatory scroll-smooth hide-scrollbar"
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
              >
                {benefits.map((benefit, index) => (
                  <div
                    key={index}
                    className="carousel-card flex-shrink-0 w-full md:w-4/5 lg:w-4/5 snap-center cursor-pointer px-1 md:px-2"
                    onClick={onContactClick}
                  >
                    <div className="flex flex-col md:flex-row items-center bg-white shadow-lg rounded-2xl md:rounded-3xl p-3 md:p-4 border-2 border-yellow-300 h-auto md:h-28 lg:h-32">
                      <div className="flex-shrink-0 w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16 bg-white border-3 md:border-4 border-[#c62b6d] rounded-full flex items-center justify-center mb-2 md:mb-0">
                        <img
                          src={benefit.icon}
                          alt={benefit.title}
                          className="w-7 h-7 md:w-8 md:h-8 lg:w-10 lg:h-10"
                        />
                      </div>

                      <div className="flex flex-col md:flex-row items-center md:space-x-4 w-full">
                        <h3 className="text-sm md:text-base lg:text-xl font-bold text-gray-700 text-center md:text-right md:w-1/2 mb-2 md:mb-0">
                          {benefit.title}
                        </h3>




                        <p className="text-gray-500 text-xs md:text-xs lg:text-sm text-center md:text-left md:w-1/2">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </section>

        <section
          className="relative h-full lg:h-screen w-full flex flex-col lg:flex-row justify-center items-center bg-no-repeat bg-cover bg-center px-8"
          style={{ backgroundImage: `url(${Randomwalkbg})` }}
        >
          <div className="w-full  text-white relative z-10 px-4 lg:px-10 font-poppins ">
            <h1 className="text-4xl lg:text-5xl font-normal text-white  text-center pt-6 md:pt-0">
              Why choose <span className="font-extrabold">Random Walk?</span>
            </h1>
            <div className="mt-8 flex flex-col lg:flex-row justify-center gap-8">
              <div className="flex flex-col items-center text-center w-full lg:w-1/2 lg:items-center lg:text-center lg:self-end mt-20">
                <img
                  src={track}
                  alt="Proven Track Record"
                  className="w-32 2xl:w-56 mb-4 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h2
                  className="text-2xl 2xl:text-3xl font-extrabold text-yellow-400 mb-10 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Proven Track Record
                </h2>
                <p
                  className="text-white text-lg 2xl:text-xl font-bold mt-2 px-16 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Successfully executed large-scale Visual AI deployments with
                  100+ KPI monitoring.
                </p>
              </div>

              {/* Advanced AI Models */}
              <div className="flex flex-col items-center text-center w-full lg:w-1/2 lg:items-center lg:text-center lg:self-end mt-20 ">
                <img
                  src={advance}
                  alt="Advanced AI Models"
                  className="w-32 2xl:w-56 mb-4 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h2
                  className="text-2xl 2xl:text-3xl font-extrabold text-yellow-400 mb-10 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Advanced AI Models
                </h2>
                <p
                  className="text-white text-lg 2xl:text-xl font-bold mt-2 px-16 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Our AI continually learns and improves to enhance accuracy and
                  efficiency.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          className="relative  w-full flex flex-col lg:flex-row justify-center items-center bg-no-repeat bg-cover bg-center px-8"
          style={{ backgroundImage: `url(${visbackground})` }}
        >

          <div className="w-full  text-white relative z-10 px-4 lg:px-10 font-poppins lg:-mt-20">
            <div className="mt-8 flex flex-col lg:flex-row justify-center gap-8">
              {/* Proven Track Record */}
              <div className="flex flex-col items-center text-center w-full lg:w-1/2 lg:items-center lg:text-center lg:self-end mt-20">
                <img
                  src={circle}
                  alt="Proven Track Record"
                  className="w-32 2xl:w-56 mb-4 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h2
                  className="text-2xl 2xl:text-3xl font-extrabold text-yellow-400 mb-10 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Seamless Integration
                </h2>
                <p
                  className="text-white text-lg 2xl:text-xl font-bold mt-2 px-16 mb-20 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Works with existing security, monitoring, and operational
                  systems.
                </p>
              </div>

              {/* Advanced AI Models */}
              <div className="flex flex-col items-center text-center w-full lg:w-1/2 lg:items-center lg:text-center lg:self-end mt-20">
                <img
                  src={hand}
                  alt="Advanced AI Models"
                  className="w-32 2xl:w-56 mb-4 cursor-pointer"
                  onClick={handleButtonClickcontact}
                />
                <h2
                  className="text-2xl 2xl:text-3xl font-extrabold text-yellow-400 mb-10 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Custom-Tailored Solutions
                </h2>
                <p
                  className="text-white text-lg 2xl:text-xl font-bold mt-2 px-16 mb-20 cursor-pointer"
                  onClick={handleButtonClickcontact}
                >
                  Visual AI applications designed to meet specific client needs.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full bg-[#7a6a6a] py-16  flex flex-col lg:flex-row items-center justify-between relative">
          <div className="text-white w-full lg:w-1/2 text-left lg:pr-12 px-8">
            <h1 className="text-3xl 2xl:text-4xl text-white lg:text-4xl font-extrabold leading-[3]">
              Take the Next Step in{" "}
              <span className="text-white">AI-Driven Transformation</span>
            </h1>

            <p className="text-xl 2xl:text-2xl  mt-4 text-yellow-400 font-medium">
              Enhance safety, streamline operations, and drive efficiency with
              <span className="font-bold">
                {" "}
                Random Walk's Visual AI solutions.
              </span>
            </p>
          </div>

          <div className="flex justify-center mt-20  lg:absolute lg:left-1/2 lg:transform lg:-translate-x-1/2 z-10 w-3/4 lg:w-auto lg:ml-32">
            <button
              className="mt-6 font-poppins px-4 lg:px-6 py-3 text-2xl sm:text-3xl 2xl:text-4xl font-bold text-white bg-[#df0060] rounded-3xl shadow-md border-4 border-[#d3689c]
    hover:bg-pink-600 transition-all duration-150"
              onClick={handleButtonClickcontact}
            >
              Ask Our Specialists
            </button>
          </div>

          <div className="mt-8 lg:mt-0 lg:ml-auto">
            <div className="bg-white p-4 w-48 lg:w-64 2xl:w-80 rounded-l-xl">
              <img src={logocolor} alt="RandomWalk Logo" className="w-full" />
            </div>
          </div>
        </section>

        {isModalOpen && (
          <div className="fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-[#F1F6FF]   p-8 lg:px-10 rounded-lg max-h-screen   max-w-md w-full">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>

              <form onSubmit={handleSubmitcontact}>
                <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                  <div className="mb-4">
                    <label className=" text-gray-700">Name</label>
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.firstname && (
                      <p className="text-red-500 text-sm">{errors.firstname}</p>
                    )}
                  </div>
                  {/* <div className="mb-4">
                    <label className=" text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="lastname"
                      id="lastname5"
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.lastname && (
                      <p className="text-red-500 text-sm">{errors.lastname}</p>
                    )}
                  </div> */}

                  <div className="mb-4 w-full">
                    <label
                      htmlFor="phoneNumber"
                      className="block text-gray-700 text-sm mb-2"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      type="tel"
                      id="Phonenumber"
                      required
                      name="phonenumber"
                      value={phoneNumber}
                      onChange={(number, country) =>
                        handlePhoneNumberChange(number, country)
                      }
                      inputClass="custom-phone-input2"
                      buttonClass="border rounded"
                      dropdownClass="border rounded"
                      placeholder="Enter Phone Number"
                      enableSearch={true}
                      inputProps={{
                        required: true,
                        autoFocus: false,
                      }}
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-700">Company Name</label>
                    <input
                      type="text"
                      id="companyname"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700">Business Email</label>
                    <input
                      type="email"
                      id="businessemail"
                      name="businessemail"
                      value={businessemail}
                      onChange={(e) => {
                        setEmailId(e.target.value);

                        if (errors.businessemail) {
                          setErrors({ ...errors, businessemail: "" });
                        }
                      }}
                      onBlur={(e) => {
                        const error = validateEmail(e.target.value);
                        if (error) {
                          setErrors({ ...errors, businessemail: error });
                        }
                      }}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.businessemail && (
                      <p className="text-red-500 text-sm">
                        {errors.businessemail}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700">
                      Project Details (Optional)
                    </label>
                    <input
                      type="text"
                      id="projectdetails"
                      name="projectdetails"
                      value={projectdetails}
                      onChange={(e) => setprojectdetails(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                </div>
                <div className="mb-4">
                  <button
                    id="submit"
                    type="submit"
                    className={`w-full px-4 py-2 bg-[#C62B6D] text-white rounded ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Visualai;
