import React, { useState, useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import BlogContentPage from "./pages/BlogContentPage.js";
import Blogs from "./pages/Blog.js";
import useFetch from "./hooks/useFetch";
import Footer from "./components/Footer.js";
import Popup from "./components/popup.js";
import Webinarpopup from "./components/Webinarpopup.js";
import HTMLSitemap from "./components/HTMLSitemap.js";
import { ThankYouMessage } from "./components/ThankYouMessage";
import { RightButtonPopup } from "./components/RightButtonPopup";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SuccessPage from "../src/Ai-read/src/pages/SuccessPage";
import CanceledPage from "../src/Ai-read/src/pages/CanceledPage";
import ScrollToTop from "./pages/ScrollToTop.js";
import styles from "../src/Brandcut/styles/App.module.css";
import demoStyles from "../src/Brandcut/styles/demo.module.css";
import HeroSection from "../src/Brandcut/components/HeroSection.js";
import SectionTwo from "../src/Brandcut/components/SectionTwo.js";
import SectionThree from "../src/Brandcut/components/SectionThree.js";
import SectionFour from "../src/Brandcut/components/SectionFour.js";
import SectionFive from "../src/Brandcut/components/SectionFive.js";
import SectionSix from "../src/Brandcut/components/SectionSix.js";
import ContactSection from "../src/Brandcut/components/ContactSection.js";

import TagManager from "react-gtm-module";
import LeadForm from "../src/Brandcut/components/LeadForm.js";
import MascotChatIcon from "../src/Brandcut/components/MascotChatIcon.js";
import ThankYouPage from "../src/Brandcut/components/ThankYouSection.js";
import LeadFormB from "../src/Brandcut/components/LeadFormB.js";
import ContactSectionB from "../src/Brandcut/components/ContactSectionB.js";
import About from "./pages/About.js";
import ThankYouPagehome from "./pages/Thankyoupage.js";
import Visualai from "./pages/visualai.js";
import Integration from "./pages/Integration.js";
import Home from "./pages/Home.js";
import Managed from "./pages/Managed.js";
import Workshop from "./pages/Workshop.js";
import Workshopduplicate from "./pages/workshopduplicate.js";
import Career from "./pages/Career.js";
import BlogContent from "./pages/BlogContent.js";
import Brochures from "./pages/Brochures.js";
import Contact from "./pages/Contact.js";
import Terms from "./pages/Terms.js";
import Privacy from "./pages/Privacy.js";
import BlogCategory from "./pages/BlogCategory.js";
import Fortunecookie from "./pages/Fortunecookie.js";
import Webinar from "./pages/Webinar.js";
import Podcast from "./pages/Podcast.js";
import Casestudy from "./pages/casestudy.js";
import Casestudycontent from "./pages/casestudycontent.js";
import { Partytown } from "@builder.io/partytown/react";
import Events from "./pages/Events";
import Media from "./pages/Media.js";
const Appjs = lazy(() => import("../src/Ai-read/src/pages/Homepage.js"));

export default function App() {
  const [showThankYou, setShowThankYou] = useState(false);
  const [isLeadFormVisible, setIsLeadFormVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();

  const [showRightButtonPopup, setShowRightButtonPopup] = useState(false);

  const handleRightButtonPopupClick = () => {
    setShowRightButtonPopup(true);
  };

  const handleCloseAllPopups = () => {
    setShowRightButtonPopup(false);
  };

  const openLeadForm = () => setIsLeadFormVisible(true);
  const helmetContext = {};
  const handleThankYouClick = () => {
    setShowThankYou(true);
  };
  const hideNavAndFooterRoutes = ["/visual-ai", "/visual-ai/","/visual-ai/thankyou/","/visual-ai/thankyou","/workshop/","/workshop"];
  const shouldHideNavAndFooter = hideNavAndFooterRoutes.includes(location.pathname);







  return (
    <>
  
 
      <HelmetProvider context={helmetContext}>
      <Suspense fallback={<div></div>}>
        {!shouldHideNavAndFooter && <Navbar />}
        <ScrollToTop />
    
          <Routes>
      
      
            <Route path="/" element={<Home />} />

            <Route path="/blog/" element={<Blogs />} />

            <Route path="/blog/:blogTitle/" element={<BlogContentPage />} />
            <Route path="/blogcontentpage/" element={<BlogContentPage />} />
            <Route path="/blogcontent/" element={<BlogContent />} />
            <Route path="/brochures/" element={<Brochures />} />
            <Route path="/ai-workshop-for-corporates/" element={<Workshop />} />
            <Route path="/workshop/" element={<Workshopduplicate />} />
            <Route path="/playlab/" element={<Events />} />
            <Route path="/media-futures/" element={<Media />} />
            <Route path="/ai-readiness-index/" element={<Appjs />} />
            <Route path="/webinars/" element={<Webinar />} />
            <Route path="/visual-ai/" element={<Visualai />} />
            <Route path="/visual-ai/thankyou/" element={<ThankYouPagehome/>}/>
            <Route path="/podcasts/" element={<Podcast />} />
            <Route path="/casestudy/" element={<Casestudy />} />
            <Route path="/casestudy/:title" element={<Casestudycontent />} />
            <Route path="/ai-integration/" element={<Integration />} />
            <Route path="/ai-managed-services/" element={<Managed />} />
          
            <Route path="/about-us/" element={<About />} />
            <Route path="/careers/" element={<Career />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/contact-us/" element={<Contact />} />
            <Route path="/terms-of-service/" element={<Terms />} />
            <Route path="/privacy-policy/" element={<Privacy />} />
            <Route path="/fortunecookie/" element={<Fortunecookie />} />
            <Route path="/sitemap.html" element={<HTMLSitemap />} />
            <Route path="/blog/category/:category" element={<BlogCategory />} />

            <Route
              path="/brandcut/"
              element={
                <div>
                  <div className={styles.section}>
                    <HeroSection onOpenLeadForm={openLeadForm} />
                  </div>
                  <SectionTwo onOpenLeadForm={openLeadForm} />
                  <div className={styles.section2}>
                    <SectionThree />
                  </div>
                  <SectionFour />
                  <SectionFive onOpenLeadForm={openLeadForm} />
                  <div className={styles.section3}>
                    <SectionSix />
                  </div>
                  <div className={styles.section4}>
                    <ContactSection
                      visible={isLeadFormVisible}
                      onClose={() => setIsLeadFormVisible(false)}
                      onSubmit={() => setIsSubmitted(true)}
                    />
                  </div>

                  <LeadForm
                    visible={isLeadFormVisible}
                    onClose={() => setIsLeadFormVisible(false)}
                    onSubmit={() => setIsSubmitted(true)}
                  />
                
                </div>
              }
            />

            <Route
              path="/thank-you"
              element={isSubmitted ? <ThankYouPage /> : <Navigate to="/" />}
            />
          </Routes>

          {!shouldHideNavAndFooter && <Footer />}
        </Suspense>
      </HelmetProvider>
    </>
  );
}
